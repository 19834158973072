/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useConfigStore } from 'configurator/reducers/configStore';
import {
  useCreateConfigTemplate,
  useDeviceConfigTemplate,
  useUpdateConfigTemplate
} from 'configurator/hooks/api/useDevice';
import { TemplateTypes } from 'configurator/utils/types';
import ModalWrapper from 'components/Modals/ModalWrapper';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';

const StyledForm = styled.form`
  display: grid;
  gap: 24px;
`;

type Inputs = {
  name: string;
  description: string;
};

type AddTemplateModalProps = {
  handleClose: (event?: React.MouseEvent<HTMLElement>) => void;
  configModes: any;
};

export const AddTemplateModal = ({ handleClose, configModes }: AddTemplateModalProps) => {
  const {
    mutateAsync: createConfigTemplate,
    isLoading,
    isError: isErrorCreate
  } = useCreateConfigTemplate();

  const onSubmit = async (data, owner, modeSlot) => {
    await createConfigTemplate({
      data: {
        name: data.name,
        description: data.description,
        config: JSON.stringify(configModes.find((mode) => mode.slot === modeSlot).config),
        owner: owner.toLowerCase()
      }
    });

    if (!isErrorCreate) {
      handleClose();
    }
  };

  return <AddTemplateForm handleClose={handleClose} onSubmit={onSubmit} isLoading={isLoading} />;
};

type EditTemplateModalProps = {
  handleClose: (event?: React.MouseEvent<HTMLElement>) => void;
  handleAfter: Function;
  templateId: number;
};

export const EditTemplateModal = ({
  handleClose,
  handleAfter,
  templateId
}: EditTemplateModalProps) => {
  const { result: templateEntry } = useDeviceConfigTemplate({ templateId });
  const {
    mutateAsync: updateConfigTemplate,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate
  } = useUpdateConfigTemplate();

  const onSubmit = async (data) => {
    await updateConfigTemplate({
      templateId,
      data: { name: data.name, description: data.description }
    });
    if (!isErrorUpdate) {
      handleAfter();
    }
  };

  if (!templateEntry) return null;

  return (
    <AddTemplateForm
      handleClose={handleClose}
      onSubmit={onSubmit}
      isLoading={isLoadingUpdate}
      templateEntry={templateEntry}
    />
  );
};

type AddTemplateFormProps = {
  handleClose: (event?: React.MouseEvent<HTMLElement>) => void;
  onSubmit: any;
  isLoading: boolean;
  templateEntry?: any;
};

const AddTemplateForm = ({
  handleClose,
  onSubmit,
  isLoading,
  templateEntry = undefined
}: AddTemplateFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>();
  const { t } = useTranslation();
  const [selectedModeSlot, setSelectedModeSlot] = useState(0);
  const modes = useConfigStore((state) => state.config.modes);

  return (
    <ModalWrapper
      title={
        templateEntry
          ? t('configurator:component.add_template_form.edit_template_name', 'Edit template name')
          : t('configurator:component.add_template_form.add_template', 'Add template')
      }>
      <StyledForm
        onSubmit={handleSubmit((data) => onSubmit(data, TemplateTypes.me, selectedModeSlot))}>
        <TextField
          id='name'
          label={t('configurator:component.add_template_form.name', 'Name')}
          required
          {...register('name', {
            required: t(
              'configurator:component.add_template_form.field_required',
              'This field is required'
            )
          })}
          error={Boolean(errors?.name)}
          helperText={errors?.name?.message}
          defaultValue={templateEntry?.name || ''}
        />
        <TextField
          id='description'
          label={t(
            'configurator:component.add_template_form.describe_the_change',
            'Describe the change'
          )}
          multiline
          required
          defaultValue={templateEntry?.description || ''}
          {...register('description')}
          error={Boolean(errors?.description)}
          helperText={errors?.description?.message}
          rows={6}
        />
        {modes && (
          <TextField
            fullWidth
            id='selected-mode'
            label={t('configurator:component.add_template_form.selected_mode', 'Selected mode')}
            select
            SelectProps={{
              value: selectedModeSlot,
              onChange: (e: any) => setSelectedModeSlot(e.target.value)
            }}>
            {modes.map((mode) => (
              <MenuItem key={`selected-mode_${mode.name}`} value={mode.slot}>
                {mode.name}
              </MenuItem>
            ))}
          </TextField>
        )}
        <FormButtonsWrapper>
          <CustomButton onClick={handleClose} color='light'>
            {t('configurator:component.add_template_form.cancel', 'Cancel')}
          </CustomButton>
          <LoadingButton type='submit' loading={isLoading}>
            <span>
              {templateEntry
                ? t('configurator:component.add_template_form.save', 'Save')
                : t('configurator:component.add_template_form.add_template', 'Add template')}
            </span>
          </LoadingButton>
        </FormButtonsWrapper>
      </StyledForm>
    </ModalWrapper>
  );
};
