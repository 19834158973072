import styled from 'styled-components';
import useCompatibilities from 'configurator/hooks/useCompatibilities';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';
import { StyledCheck, StyledTimes } from 'configurator/modals/FirmwareModal/styled';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';

const Wrapper = styled.div`
  display: grid;
  gap: 20px;
`;

const InfoWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const PcbReplacementFinish = () => {
  const { versions } = useDeviceInfoStore((state: any) => ({
    versions: state.versions,
    deviceId: state.deviceId
  }));
  const { availableFirmwares, isFirmwareUpdateAvailable, isFirmwareUpdateNeeded } =
    useCompatibilities();
  const firmwareVersionCurrentParsed = `${versions?.current?.[1]}.${versions?.current?.[4]}.${versions?.current?.[7]}`;
  const { disconnectDevice, getInitialConfigAPI } = useConfigStore((state: any) => ({
    disconnectDevice: state.disconnectDevice,
    getInitialConfigAPI: state.getInitialConfigAPI
  }));
  const { openModal, closeModal } = useUiStore((state: any) => ({
    openModal: state.openModal,
    closeModal: state.closeModal
  }));

  const finish = async () => {
    closeModal(MODALS.repair);
    await disconnectDevice();
    await getInitialConfigAPI();
  };

  const handleFirmware = () => {
    openModal(MODALS.firmware);
    closeModal(MODALS.repair);
  };

  const cancel = () => {
    closeModal(MODALS.repair);
  };

  return (
    <Wrapper>
      <InfoWrapper>
        <div>Device version: {firmwareVersionCurrentParsed}</div>
        <div>Newest version: {availableFirmwares?.[0]?.name}</div>
        {isFirmwareUpdateAvailable || isFirmwareUpdateNeeded ? (
          <>
            (Outdated) <StyledTimes />
          </>
        ) : (
          <>
            <StyledCheck />
          </>
        )}
      </InfoWrapper>
      <FormButtonsWrapper>
        {isFirmwareUpdateAvailable || isFirmwareUpdateNeeded ? (
          <>
            <CustomButton onClick={cancel}>Close</CustomButton>
            <CustomButton onClick={handleFirmware}>Update firmware</CustomButton>
          </>
        ) : (
          <CustomButton onClick={finish}>Close</CustomButton>
        )}
      </FormButtonsWrapper>
    </Wrapper>
  );
};

export default PcbReplacementFinish;
