import { useBackupConfigActions } from 'configurator/hooks/useBackupConfigActions';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import useUnsaved from 'configurator/hooks/useUnsaved';
import { pauseSoundElement, playSoundElement } from 'App';
import { useUiStore } from 'configurator/reducers/uiStore';
import { FETCHING_STATES } from 'configurator/consts/consts';
import ModalWrapper from 'components/Modals/ModalWrapper';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';

const ParagraphBold = styled.p`
  ${TextS};
`;

type ModalProps = {
  handleClose: Function;
};

const LocalSessionConfigBackupRestoreModal = ({ handleClose }: ModalProps) => {
  const { isUnsaved } = useUnsaved();
  const { t } = useTranslation('configurator');
  const { deviceId, amputeeId } = useDeviceInfoStore((state: any) => ({
    deviceId: state.deviceId,
    amputeeId: state.amputeeId
  }));
  const { initialConfigState, initialConfigApiState, bluetoothState, synchronizingState } =
    useUiStore((state) => ({
      initialConfigState: state.initialConfigState,
      initialConfigApiState: state.initialConfigApiState,
      bluetoothState: state.bluetoothState,
      synchronizingState: state.synchronizingState
    }));

  const { handleAcceptBackup, handleDenyBackup } = useBackupConfigActions(deviceId);

  useEffect(() => {
    playSoundElement();
  }, []);

  const accepted = () => {
    pauseSoundElement();
    handleAcceptBackup();
    handleClose();
  };

  const denied = () => {
    pauseSoundElement();
    handleDenyBackup();
    handleClose();
  };

  const canApplySettings =
    !isUnsaved &&
    initialConfigState === FETCHING_STATES.successful &&
    bluetoothState === FETCHING_STATES.successful &&
    synchronizingState === FETCHING_STATES.successful &&
    initialConfigApiState === FETCHING_STATES.successful;

  const handleOnClick = () => {
    pauseSoundElement();
    handleClose();
  };

  return (
    <ModalWrapper
      title={t(
        'configurator:component.modal.local_session_config_backup_restore_modal.header',
        'Backup config found'
      )}>
      <ParagraphBold>
        {t(
          'configurator:component.modal.local_session_config_backup_restore_modal.restore_message',
          'We have created a restore point for your last disconnected session.'
        )}
      </ParagraphBold>
      <FormButtonsWrapper>
        <CustomButton color='light' onClick={handleOnClick}>
          {t('configurator:component.close_session_modal.cancel', 'Cancel')}
        </CustomButton>
        <CustomButton loadingPosition='start' loading={!canApplySettings} onClick={accepted}>
          {t(
            'configurator:component.modal.local_session_config_backup_restore_modal.button_accept',
            'Yes, restore'
          )}
        </CustomButton>
        <CustomButton onClick={denied} color={amputeeId ? 'light' : 'primary'}>
          {t(
            'configurator:component.modal.local_session_config_backup_restore_modal.button_cancel',
            "No, don't restore"
          )}
        </CustomButton>
      </FormButtonsWrapper>
    </ModalWrapper>
  );
};

export default LocalSessionConfigBackupRestoreModal;
