/* eslint-disable react/jsx-key */
import { useState } from 'react';
import { MenuItem, TextField, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import { transformCompared } from 'configurator/utils/Config/transformConfig';
import { useConfigStore } from 'configurator/reducers/configStore';
import { TableBody, TableHeader } from 'configurator/views/DeviceConfig/DeviceConfig/styled';
import { ConfigHistoryItem } from 'configurator/components/organisms/Config/ConfigHistoryItem';
import ModalWrapper from 'components/Modals/ModalWrapper';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';

const ParagraphBold = styled.p`
  ${TextS};
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const ChangesWrapper = styled.div`
  overflow-y: scroll;
  max-height: 400px;
  padding: 0 16px;
  margin-bottom: 16px;
`;

type ConfirmAbortModeProps = {
  handleClose: any;
  handleAccept: any;
  entry: any;
  isLoading: boolean;
};

const RestoreConfigHistoryModal = ({
  handleClose,
  handleAccept,
  entry,
  isLoading
}: ConfirmAbortModeProps) => {
  const { t } = useTranslation();
  const { common, modes } = useConfigStore((state: any) => ({
    common: state.config.common,
    modes: state.config.modes,
    slotSelected: state.slotSelected
  }));
  const [selectedModeSlot, setSelectedModeSlot] = useState(0);

  const configNow = {
    ...common.configAPI,
    ...modes.find((mode: any) => mode.slot === selectedModeSlot)?.configAPI
  };

  const compared = transformCompared(configNow, {
    ...entry.common,
    ...entry.modes.find((mode: any) => mode.slot === selectedModeSlot).config
  });

  return (
    <ModalWrapper
      title={t(
        'configurator:component.restore_config_history_modal.save_configuration',
        'Save configuration'
      )}>
      <ParagraphBold>
        {t(
          'configurator:component.restore_config_history_modal.do_you_want_to_restore_and_send',
          'Do you want to restore this configuration and send it to patient?'
        )}
      </ParagraphBold>
      {modes && (
        <TextField
          fullWidth
          id='selected-mode'
          label={t(
            'configurator:component.restore_config_history_modal.difference_for_mode',
            'Difference for mode'
          )}
          select
          sx={{ marginBottom: '20px' }}
          SelectProps={{
            value: selectedModeSlot,
            onChange: (e: any) => setSelectedModeSlot(e.target.value)
          }}>
          {modes.map((mode: any) => (
            <MenuItem key={`selected-mode_${mode.name}`} value={mode.slot}>
              {mode.name}
            </MenuItem>
          ))}
        </TextField>
      )}
      {compared?.length > 0 && (
        <ChangesWrapper>
          <TableBody sticky>
            <TableHeader>
              {t(
                'configurator:component.restore_config_history_modal.configuration',
                'Configuration'
              )}
            </TableHeader>
            <TableHeader>
              {t('configurator:component.restore_config_history_modal.being_sent', 'Being sent')}
            </TableHeader>
            <TableHeader>
              {t('configurator:component.restore_config_history_modal.current', 'Current')}
            </TableHeader>
          </TableBody>
          {compared.map((entry: any) => (
            <ConfigHistoryItem
              name={entry.key}
              configName={entry.key}
              after={entry.value}
              now={configNow}
            />
          ))}
        </ChangesWrapper>
      )}
      <FormButtonsWrapper>
        <CustomButton onClick={handleClose} color='light' type='button'>
          {t('configurator:component.restore_config_history_modal.cancel', 'Cancel')}
        </CustomButton>
        <LoadingButton type='button' onClick={handleAccept} loading={isLoading}>
          <span>
            {t('configurator:component.restore_config_history_modal.yes_send', 'Yes, send')}
          </span>
        </LoadingButton>
      </FormButtonsWrapper>
    </ModalWrapper>
  );
};

export default RestoreConfigHistoryModal;
