import './theme/web-therapy.css';
import * as Sentry from '@sentry/react';
import { setApiAuthToken } from 'adp-panel/api/utils/apiClient';
import useDocumentsAcceptanceModal from 'adp-panel/components/DocumentsAcceptance/DocumentsAcceptance';
import Toaster from 'adp-panel/components/Toaster/Toaster';
import { LIVE_CHAT_LICENCE } from 'adp-panel/constants/config';
import useRefreshToken from 'adp-panel/hoc/useRefreshToken';
import useLocalizeDocumentAttributes from 'adp-panel/hooks/useLocalizeDocumentAttributes';
import AuthenticatedAppLayout from 'app/AuthenticatedAppLayout';
import NotProtectedApp from 'app/NotProtectedApp';
import BluetoothEventsHandler from 'configurator/bluetooth-handler/bluetoothEventsHandler';
import BootloaderController from 'configurator/bluetooth-handler/bootloaderController';
import LiveConfigurator from 'configurator/components/organisms/LiveConfigurator/LiveConfigurator';
import useBluetooth from 'configurator/hooks/bluetooth/useConnect';
import useModes from 'configurator/hooks/useModes';
import useSidebar from 'configurator/hooks/useSidebar';
import useUnsaved from 'configurator/hooks/useUnsaved';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import { useUiStore } from 'configurator/reducers/uiStore';
import Modals, { MODALS, MODALS_ARGS } from 'configurator/views/Modals';
import WelcomePage from 'configurator/views/WelcomePage';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuthStore } from 'reducers/authStore';
import { useRedirectStore } from 'reducers/redirectReducer';
import { filteredRedirectUrls } from 'utils/filterRoutes';
import ConfirmationLoader from './adp-panel/layouts/ConfirmationLoader';
import ErrorBoundary from './adp-panel/layouts/ErrorBoundary';
import TicketRedirect from './adp-panel/pages/TicketRedirect/TicketRedirect';
import * as routes from './constants/routes';
import useUserData from './hooks/useUserData';
import { LiveChatWidget } from '@livechat/widget-react';
import { ProductFruits } from 'react-product-fruits';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import useIsOnline from 'hooks/useIsOnline.';

const Bootloader = new BootloaderController();

export const playSoundElement = () => {
  const audio_file1: HTMLAudioElement | null = document.getElementById(
    'audioID'
  ) as HTMLAudioElement;

  if (!audio_file1) return;

  audio_file1.volume = 0.2;
  audio_file1.currentTime = 0;
  audio_file1.play();
};

export const pauseSoundElement = () => {
  const audio_file1: HTMLAudioElement | null = document.getElementById(
    'audioID'
  ) as HTMLAudioElement;

  if (!audio_file1) return;

  audio_file1.pause();
};

const App = () => {
  const { mfa, token, isUserLoggingOut } = useAuthStore((state) => ({
    token: state.token,
    mfa: state.mfa,
    isUserLoggingOut: state.isUserLoggingOut
  }));
  const { t } = useTranslation();
  const { refreshToken } = useRefreshToken();
  const [show, setShow] = useState(false);
  const currentUser = useUserData();
  const { data: userData, rolesByName, isLoading: loadingUserData } = currentUser;
  const ticketId = useRedirectStore((state) => state.ticketId);
  const loggedIn = Boolean(token);
  const isOnline = useIsOnline();
  const { isLoadingDocuments, documentsResults } = useDocumentsAcceptanceModal(
    loggedIn && userData !== undefined
  );
  const { bluetoothDisconnect } = useBluetooth();
  const { isUnsaved } = useUnsaved();
  const { ticketCanBeSent } = useSidebar();
  const { blockModals, isModalOpen } = useUiStore((state) => ({
    setItemUiStore: state.setItemUiStore,
    blockModals: state.blockModals,
    isModalOpen: state.isModalOpen
  }));
  const { deviceConnected } = useDeviceInfoStore((state) => ({ deviceConnected: state.connected }));
  const remoteSessionState = useLiveConfiguratorStore((state) => state);
  const { enabled: remoteSessionEnabled } = remoteSessionState;
  const { handleOpenSave } = useModes();

  useLocalizeDocumentAttributes();

  const showDashboard =
    loggedIn && userData !== undefined && mfa.required === false && null !== documentsResults;

  useEffect(() => {
    if (Boolean(token) && mfa.required === false && userData !== undefined) {
      setShow(true);
      Sentry.setUser(userData);
      return;
    }

    if (token && mfa.required === true) {
      setShow(true);
      Sentry.setUser(userData);
      return;
    }

    if (!token) {
      refreshToken()
        .then(() => {})
        .catch(() => {
          if (filteredRedirectUrls(window.location.pathname).length > 0) {
            localStorage.setItem('redirectUrl', window.location.pathname + window.location.search);
          }
          setShow(true);
          return;
        });
    }

    setShow(false);
  }, [token, userData, refreshToken, mfa]);

  useEffect(() => {
    setApiAuthToken(token);
  }, [token]);

  useEffect(() => {
    if (isUserLoggingOut) {
      setShow(false);
    }
  }, [isUserLoggingOut]);

  const mfaScreen = token && show && !userData;

  useEffect(() => {
    Bootloader.listenBootloaderStatus();
    BluetoothEventsHandler.initiateBluetoothEventsListening();

    return function clearConnection() {
      if (deviceConnected) {
        bluetoothDisconnect();
      }
    };
  }, []);

  const preventBrowserWarningModalIsOpen =
    isModalOpen(MODALS.firmware) ||
    isModalOpen(MODALS.confirmSave) ||
    isModalOpen(MODALS.closeSession) ||
    isModalOpen(MODALS.disruptiveDisconnect);

  useEffect(() => {
    function handleLeave(event) {
      if (
        isUnsaved &&
        !preventBrowserWarningModalIsOpen &&
        !remoteSessionEnabled &&
        ticketCanBeSent &&
        token
      ) {
        playSoundElement();
        handleOpenSave(isUnsaved, {
          action: () => false,
          args: MODALS_ARGS.unsavedChangesDisconnect
        });
        event.returnValue = 'There is pending work. Sure you want to leave?';
      }
    }

    window.addEventListener('beforeunload', handleLeave);

    return () => window.removeEventListener('beforeunload', handleLeave);
  }, [
    isUnsaved,
    blockModals,
    remoteSessionEnabled,
    ticketCanBeSent,
    preventBrowserWarningModalIsOpen,
    token
  ]);

  useEffect(() => {
    if (!isOnline) {
      toast(
        t(
          'notifications:offline_status',
          'It seems you are offline, please check internet connection.'
        ),
        { icon: '⚠️', id: 'offlineStatus', duration: Infinity }
      );
    } else {
      toast.dismiss('offlineStatus');
    }
  }, [isOnline]);

  const loadingNecessaryData = !show || isLoadingDocuments || loadingUserData;

  return (
    <>
      {loggedIn && userData && (
        <ProductFruits
          workspaceCode='q8OGYfraT17dmNxs'
          language={userData.language ? userData.language : 'en'}
          user={{
            username: String(userData.id),
            email: userData.email,
            role: userData?.role_name
          }}
        />
      )}
      <LiveChatWidget license={LIVE_CHAT_LICENCE} />
      <LiveConfigurator remoteSessionState={{ ...remoteSessionState, isUnsaved }}>
        <Toaster />
        <Modals />
        <div id='modal-root' />
        <audio
          id='audioID'
          src='https://aetherbiomedical-images.s3.amazonaws.com/soundEffect.wav'
          preload='auto'
        />
        {!loadingNecessaryData && showDashboard && ticketId && (
          <>
            <ErrorBoundary>
              <Routes>
                <Route path='*' element={<Navigate to={routes.DASHBOARD} replace />} />
              </Routes>
            </ErrorBoundary>
          </>
        )}
        {!loadingNecessaryData && showDashboard && (
          <AuthenticatedAppLayout currentUser={currentUser} rolesByName={rolesByName} />
        )}
        {!loadingNecessaryData && !showDashboard && <NotProtectedApp loggedIn={loggedIn} />}
        {!token && show && (
          <Routes>
            <Route path={routes.CONFIGURATOR_HOME} element={<WelcomePage />} />
          </Routes>
        )}
        {!show && (
          <ErrorBoundary>
            <Routes>
              <Route path={routes.TICKET_REDIRECT} element={<TicketRedirect />} />
            </Routes>
            <ConfirmationLoader fullScreen />
          </ErrorBoundary>
        )}
      </LiveConfigurator>
    </>
  );
};

export default Sentry.withProfiler(App);
