import CustomButton from 'components/Button/CustomButton';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { styled as styledMui } from '@mui/material/styles';
import ModalWrapper from 'components/Modals/ModalWrapper';

const StyledTopicLabel = styledMui(Typography)`
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #475467;
`;

const StyledTopicText = styledMui(Typography)`
  color: #101828;
  font-size: 14px;
  font-weight: 500;
`;

const ModalContent = styled.p`
  color: #101828;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
`;

const InnerWrapper = styled.div`
  width: 100%;
  //margin: 16px 16px 16px 24px;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  margin-top: 36px;
  margin-bottom: 10px;
  justify-content: center;
`;

type MessageModalProps = {
  handleClose: any;
  content: string;
  title: string;
  date: string;
  hour: string;
  sender: string;
  onModalOpen: any;
};

const MessageModal = ({
  title,
  handleClose,
  content,
  date,
  hour,
  sender,
  onModalOpen
}: MessageModalProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (onModalOpen) onModalOpen();
  }, []);

  return (
    <ModalWrapper title={title}>
      <InnerWrapper>
        <ModalContent dangerouslySetInnerHTML={{ __html: content }} />
        <Divider style={{ margin: '16px 0' }} />
        <Stack direction='row' spacing={6}>
          <Box>
            <StyledTopicLabel>{t('common:component.message_modal.from', 'From:')}</StyledTopicLabel>
            <StyledTopicText>{sender}</StyledTopicText>
          </Box>
          <Box>
            <StyledTopicLabel>
              {t('common:component.message_modal.created_at', 'Created at:')}
            </StyledTopicLabel>
            <StyledTopicText>
              {date} {hour}
            </StyledTopicText>
          </Box>
        </Stack>
        <ButtonsWrapper>
          <CustomButton
            data-testid='cancel-verify-phone'
            type='button'
            color='light'
            onClick={handleClose}>
            {t('common:verify_phone_modal.buttons.close', 'Close')}
          </CustomButton>
        </ButtonsWrapper>
      </InnerWrapper>
    </ModalWrapper>
  );
};

export default MessageModal;
