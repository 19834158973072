import { filteredGripsGroupsOptionsMap } from '../gripsDefinition';

export type TimeGridUsageData = {
  id: string;
  label: string;
  // color: string;
  'Grip count': number;
  Percentage: string;
  description: string;
};

type GripTimeData = {
  device_id: number;
  time: number;
  grip: number;
  percentage_rounded: string;
  group_by: number;
  instances: number;
  percentage: string;
  Percentage: string;
};

type GripUsageData = {
  group_by: number;
  instances: number;
  time: number;
  percentage: string;
  'Grip count': number;
  'Grip switches': number;
  'Time spent': number;
  Percentage: string;
  percentage_rounded: string;
  grip: number;
  label_name: string;
};

export const mapTotalGripData = (items: any) => {
  const baseData = Array.from(filteredGripsGroupsOptionsMap.entries()).map(
    ([gripId, gripName]): GripUsageData => ({
      grip: gripId,
      percentage_rounded: '0.00',
      group_by: gripId,
      instances: 0,
      time: 0,
      percentage: '0.00',
      'Grip count': 0,
      'Grip switches': 0,
      'Time spent': 0,
      Percentage: '0.00',
      label_name: gripName
    })
  );

  if (!items?.length) {
    return baseData;
  }

  const existingDataMap = new Map<number, GripUsageData>(
    items.map((item: GripUsageData) => [Number(item.group_by), item])
  );

  return baseData.map((baseItem) => {
    const existingItem = existingDataMap.get(baseItem.group_by);
    if (!existingItem) return baseItem;

    return {
      ...baseItem,
      instances: existingItem.instances || 0,
      time: existingItem.time || 0,
      percentage: existingItem.percentage || '0.00',
      'Grip count': existingItem.instances || 0,
      'Grip switches': existingItem.instances || 0,
      'Time spent': existingItem.time ? Math.floor(existingItem.time / 60) : 0,
      Percentage: existingItem.percentage ? parseFloat(existingItem.percentage).toFixed(2) : '0.00'
    };
  });
};

export const mapGripTimeData = (items: any): TimeGridUsageData[] => {
  if (!items?.length) {
    return [];
  }

  return items.map((item: GripTimeData) => ({
    id: filteredGripsGroupsOptionsMap.get(Number(item.group_by)) || '',
    label: filteredGripsGroupsOptionsMap.get(Number(item.group_by)) || '',
    instances: item.instances || 0,
    'Grip count': item.instances || 0,
    'Grip switches': item.instances || 0,
    'Time spent': item.time ? Math.floor(item.time / 60) : 0,
    Percentage: item.percentage ? parseFloat(item.percentage).toFixed(2) : '0.00',
    description: `Time spent in ${filteredGripsGroupsOptionsMap.get(Number(item.group_by))} grip`
  }));
};
