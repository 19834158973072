/* eslint-disable no-unused-vars */
import LoaderWrapper from 'components/Loader/Loader';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';
import { CircularProgress, TextField } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import { MODALS, MODALS_ARGS } from 'configurator/views/Modals';
import { useUiStore } from 'configurator/reducers/uiStore';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import useSynchronizeBluetooth from 'configurator/hooks/bluetooth/useSynchronizeBluetooth';
import useBluetooth from 'configurator/hooks/bluetooth/useConnect';
import useRemoteSession from 'configurator/hooks/useRemoteSession';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import ModalWrapper from 'components/Modals/ModalWrapper';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';

const ParagraphBold = styled.p`
  ${TextS};
  margin-bottom: 20px;
`;

type ConfirmAbortModeProps = {
  handleClose: (event?: React.MouseEvent<HTMLElement>) => void;
  handleAccept: Function;
  localMode?: boolean;
  isLoading: boolean;
};

type Inputs = {
  note: string;
};

const ConfirmSave = ({
  handleClose,
  handleAccept,
  localMode = false,
  isLoading
}: ConfirmAbortModeProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>();
  const { t } = useTranslation();
  const { additionalModalArgs } = useUiStore((state) => ({
    additionalModalArgs: state.additionalModalArgs
  }));
  const additionalArgs = additionalModalArgs(MODALS.confirmSave)?.args;
  const { config, getInitialConfigAPI } = useConfigStore((state) => ({
    config: state.config,
    getInitialConfigAPI: state.getInitialConfigAPI
  }));
  const { deviceConnected, deviceId } = useDeviceInfoStore((state: any) => ({
    deviceConnected: state.connected,
    deviceId: state.deviceId
  }));
  const { synchronizeConfig } = useSynchronizeBluetooth();
  const { bluetoothDisconnect } = useBluetooth();
  const liveConfiguratorEnabled = useLiveConfiguratorStore((state) => state.enabled);
  const { sendQuickSave } = useRemoteSession();
  const isLeavingConfiguratorWithUnsavedChanges =
    additionalArgs?.args === MODALS_ARGS.unsavedChanges;
  const isUnsavedChangesDisconnect = additionalArgs?.args === MODALS_ARGS.unsavedChangesDisconnect;
  const isDisconnectWithUnsavedFromAdp = additionalArgs?.unsavedChanges === true;

  const leaving =
    isLeavingConfiguratorWithUnsavedChanges ||
    isUnsavedChangesDisconnect ||
    isDisconnectWithUnsavedFromAdp;

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await handleAccept(data);

    if (leaving && deviceConnected) {
      bluetoothDisconnect();
    }

    if (additionalArgs?.action) {
      additionalArgs.action();
    }

    if (liveConfiguratorEnabled) {
      sendQuickSave();
    }
  };

  const close = async () => {
    if (leaving && deviceConnected) {
      await synchronizeConfig(config);
      bluetoothDisconnect();
      await getInitialConfigAPI();
    }

    if (isLeavingConfiguratorWithUnsavedChanges) {
      await getInitialConfigAPI();
    }

    if (additionalArgs?.action) {
      additionalArgs.action();
    }

    localStorage.removeItem(`localSessionBackupConfig-${deviceId}`);
    handleClose();
  };

  const HEADER_TEXT = leaving
    ? t('configurator:component.confirm_save.unsaved_changes', 'Unsaved changes')
    : t('configurator:component.confirm_save.save_configuration', 'Save configuration');

  const CLOSE_BUTTON_TEXT = leaving
    ? t('configurator:component.confirm_save.no_dont_save', "No, don't save")
    : t('configurator:component.confirm_save.close', 'Close');

  return (
    <ModalWrapper title={HEADER_TEXT}>
      {isLoading ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <ParagraphBold>
            {leaving &&
              t(
                'configurator:component.confirm_save.there_are_unsaved_changes',
                'There are unsaved changes. '
              )}
            {localMode
              ? t(
                  'configurator:component.confirm_save.do_you_want_to_save_current_configuration',
                  'Do you want to save current configuration?'
                )
              : t(
                  'configurator:component.confirm_save.do_you_want_to_save_and_send_to_patient',
                  'Do you want to save current configuration and send it to patient?'
                )}
          </ParagraphBold>
          {!localMode && (
            <TextField
              fullWidth
              id='description'
              label={t('configurator:component.confirm_save.label', 'Description')}
              multiline
              required
              placeholder={t(
                'configurator:component.confirm_save.describe_the_change',
                'Describe the change'
              )}
              {...register('note', {
                required: t(
                  'configurator:component.confirm_save.field_required',
                  'This field is required.'
                )
              })}
              error={Boolean(errors?.note)}
              helperText={errors?.note?.message}
              rows={6}
            />
          )}
          <FormButtonsWrapper>
            <CustomButton onClick={close} variant='outlined'>
              {CLOSE_BUTTON_TEXT}
            </CustomButton>
            <LoadingButton type='submit'>
              <span>{t('configurator:component.confirm_save.yes_save', 'Yes, save')}</span>
            </LoadingButton>
          </FormButtonsWrapper>
        </form>
      )}
    </ModalWrapper>
  );
};

export default ConfirmSave;
