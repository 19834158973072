import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import { StyledCheck } from 'configurator/components/atoms/Icons/Icons';
import ModalWrapper from 'components/Modals/ModalWrapper';
import CustomButton from 'components/Button/CustomButton';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';

const ParagraphBold = styled.p`
  ${TextS};
  margin-top: 20px;
  margin-bottom: 40px;
`;

const CheckIcon = styled(StyledCheck)`
  margin: 10px 0;
  width: 48px;
  height: 48px;
`;

const ContentWrapper = styled.div`
  text-align: center;
`;
type ModalProps = {
  handleClose: any;
};

const ResetAllChangesModal = ({ handleClose }: ModalProps) => {
  const { t } = useTranslation();
  return (
    <ModalWrapper
      title={t(
        'configurator:component.reset_all_changes_modal.save_as_restore_point',
        'Save as restore point'
      )}>
      <ContentWrapper>
        <CheckIcon />
        <ParagraphBold>
          {t(
            'configurator:component.reset_all_changes_modal.restore_point_created',
            'The restore point was successfully created.'
          )}
        </ParagraphBold>
      </ContentWrapper>
      <FormButtonsWrapper>
        <CustomButton color='light' onClick={handleClose}>
          {t('configurator:component.reset_all_changes_modal.close', 'Close')}
        </CustomButton>
      </FormButtonsWrapper>
    </ModalWrapper>
  );
};

export default ResetAllChangesModal;
