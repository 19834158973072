/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { PCBVersionEntry } from 'configurator/api/versions/versions.types';
import PcbReplacementBluetooth from './PcbReplacementBluetooth';
import PcbReplacementConnection from './PcbReplacementConnection';
import PcbReplacementFirmware from './PcbReplacementFirmware';
import PcbReplacementInstruction from './PcbReplacementInstructions';
import ModalWrapper from 'components/Modals/ModalWrapper';

enum FormStep {
  BluetoothId,
  Instruction,
  Connection,
  FirmwareCheck
}

type PcbReplacementModalProps = {
  handleClose: Function;
};

const PcbReplacementModal = ({ handleClose }: PcbReplacementModalProps) => {
  const [step, setStep] = useState<FormStep>(FormStep.FirmwareCheck);
  const [bluetoothId, setBluetoothId] = useState<string>();
  const [selectedPcb, setSelectedPcb] = useState<PCBVersionEntry>();

  switch (step) {
    case FormStep.BluetoothId:
      return (
        <ModalWrapper title='Replacement'>
          <PcbReplacementBluetooth
            handleClose={handleClose}
            handleNext={() => setStep((prev) => prev + 1)}
            handleBluetooth={(blId) => setBluetoothId(blId)}
            handlePcb={(pcb) => setSelectedPcb(pcb)}
            bluetoothId={bluetoothId}
            pcb={selectedPcb}
          />
        </ModalWrapper>
      );
    case FormStep.Instruction:
      return (
        <ModalWrapper title='Replacement'>
          <PcbReplacementInstruction
            handlePrevious={() => setStep((prev) => prev - 1)}
            handleNext={() => setStep((prev) => prev + 1)}
            handleClose={handleClose}
          />
        </ModalWrapper>
      );
    case FormStep.Connection:
      return (
        <ModalWrapper title='Replacement'>
          {bluetoothId && (
            <PcbReplacementConnection
              handlePrevious={() => setStep((prev) => prev - 1)}
              handleNext={() => setStep((prev) => prev + 1)}
              bluetoothId={bluetoothId}
              pcb={selectedPcb!}
              handleClose={handleClose}
            />
          )}
        </ModalWrapper>
      );
    case FormStep.FirmwareCheck:
      return (
        <ModalWrapper title='Replacement'>
          <PcbReplacementFirmware />
        </ModalWrapper>
      );
    default:
      return null;
  }
};

export default PcbReplacementModal;
