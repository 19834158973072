import { CircularProgress, Tooltip } from '@mui/material';
import { useAddPatient } from 'adp-panel/pages/Patients/useAddPatient';
import CustomButton from 'components/Button/CustomButton';
import { FormWrapper } from 'components/FormFields/commonStyles';
import FormComboBox, {
  clinicianOption,
  mapDevices,
  mapPatients
} from 'components/FormFields/FormComboBox';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { myTheme } from 'theme/theme';
import FormButtonsWrapper from '../../../components/FormInput/FormButtonsWrapper';
import { ReactComponent as PlusSvg } from 'assets/plus.svg';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupedFormField } from 'adp-panel/pages/Patients/styled';
import CustomTextField from 'components/FormFields/CustomTextField';
import CustomPasswordField from 'components/FormFields/CustomPasswordField';
import { useState } from 'react';
import { emailSchema, isStrongPassword, stringSchema } from 'configurator/views/Register/utils';
import { VALIDATOR_TEXT } from 'constants/validatorText';
import { useUsersInfinite } from 'adp-panel/hooks/api/useUsers';
import { UsersQueryParams } from 'adp-panel/api/users/users.types';
import { useDeviceUpdate } from 'adp-panel/hooks/api/useDevices';
import * as Sentry from '@sentry/react';
import LoaderWrapper from 'components/Loader/Loader';
import { ErrorComponent } from '../../../components/Error/ErrorComponent';
import { ReactComponent as AddUserSvg } from 'assets/add_user.svg';
import useUserData from 'hooks/useUserData';
import ModalWrapper from 'components/Modals/ModalWrapper';

const deviceAssignSchema = {
  patient: yup
    .mixed()
    .test('is-object', VALIDATOR_TEXT.REQUIRED, (value) => {
      if (value !== null && typeof value === 'object') return true;
      return false;
    })
    .nullable()
};

const patientCreateSchema = {
  firstName: stringSchema.required(VALIDATOR_TEXT.REQUIRED),
  lastName: stringSchema.required(VALIDATOR_TEXT.REQUIRED),
  email: emailSchema.required(VALIDATOR_TEXT.REQUIRED),
  password: yup.string().test('isValidPass', VALIDATOR_TEXT.PASSWORD_NOT_VALID, (value) => {
    if (value === undefined || value === null || value === '') return true;

    return isStrongPassword(value);
  }),
  retypePassword: yup
    .string()
    .test('passwords-match', VALIDATOR_TEXT.PASSWORDS_MUST_MATCH, function (value) {
      const parentPassword = this.parent.password;
      if (parentPassword === undefined || parentPassword === null || parentPassword === '')
        return true;

      return parentPassword === value;
    })
};

interface AssignDeviceModalProps {
  isModalOpen: boolean;
  handleModalClose: any;
  device: any;
  patient: any;
}

const AssignDeviceModal = ({
  isModalOpen,
  handleModalClose,
  device,
  patient = null
}: AssignDeviceModalProps) => {
  const { t } = useTranslation();
  const queryParams: UsersQueryParams = {
    roles: 'Amputee',
    perpage: 100
  };
  const { result: patientsData, isLoading: isLoadingPatients } = useUsersInfinite(queryParams);
  const { data: me, isLoading: isLoadingMe } = useUserData();

  const defaultValues = {
    device: device,
    patient: patient
      ? mapPatients([
          {
            ...patient,
            email: device.amputee_accessible ? patient.email : null
          }
        ])[0]
      : null,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    retypePassword: ''
  };

  const [showPatientForm, setShowPatientForm] = useState(false);

  const validationSchema = showPatientForm ? { ...patientCreateSchema } : { ...deviceAssignSchema };
  const {
    control,
    handleSubmit: handleSubmitDevice,
    watch,
    reset
  } = useForm<{
    device: any;
    patient: any;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    retypePassword: string;
  }>({
    defaultValues: defaultValues,
    // @ts-ignore
    resolver: yupResolver(yup.object().shape(validationSchema)),
    mode: 'onChange',
    shouldUnregister: false
  });
  const [password, patientForm] = watch(['password', 'patient']);

  const { mutateAsync: updateDevice, isLoading: isLoadingUpdate } = useDeviceUpdate();
  const { handleSubmit: handleSubmitAddPatient, isLoading: isLoadingAddPatient } = useAddPatient();

  const handleSubmit = async (e: any) => {
    const { patient = undefined, email = undefined } = e;

    try {
      let result;
      if (email) {
        result = await handleSubmitAddPatient({
          ...e,
          cancelRedirect: true,
          clinician: { id: me.id }
        });
      } else {
        result = await updateDevice({
          data: {
            amputee_id: patient.id
          },
          deviceId: device.id,
          successMessage: t(
            'notifications:device_assigned_success',
            'Device has been assigned to patient'
          )
        });
      }
      if (result) handleModalClose();
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const isAlreadyAssignedPatientSelected = Boolean(
    patient && !showPatientForm && patient?.id === patientForm?.id
  );

  const showForm = () => {
    if (isLoadingMe || isLoadingPatients)
      return (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      );

    if (me && patientsData)
      return (
        <form onSubmit={handleSubmitDevice(handleSubmit)}>
          <FormWrapper>
            <div style={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
              <FormComboBox
                label={t('devices:assign_device_modal.form.fields.device', 'Device')}
                id={'device'}
                control={control}
                options={[device]}
                value={mapDevices([device])[0]}
                disabled
                optionLabel='fullName'
              />
              {!showPatientForm && (
                <FormComboBox
                  label='Patient'
                  id={'patient'}
                  control={control}
                  options={mapPatients(patientsData)}
                  renderOption={(props, option) =>
                    clinicianOption(props, option, 'fullName', 'name')
                  }
                  optionLabel={'fullName'}
                  nameProperty='name'
                  placeholder={t(
                    'devices:assign_device_modal.form.fields.search_placeholder',
                    'Search by name or e-mail'
                  )}
                  loading={isLoadingPatients}
                  noEndAdornment
                />
              )}
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr auto 1fr',
                alignItems: 'center'
              }}>
              <div style={{ height: '1px', border: `1px solid ${myTheme.palette.primary}` }} />
              <CustomButton
                onClick={() => setShowPatientForm((prev) => !prev)}
                type='button'
                variant='text'
                Icon={showPatientForm ? AddUserSvg : PlusSvg}
                sx={{ margin: '0 4px' }}>
                {showPatientForm
                  ? t(
                      'devices:assign_device_modal.form.buttons.assign_existing_patient',
                      'Assign existing patient'
                    )
                  : t(
                      'devices:assign_device_modal.form.buttons.add_new_patient',
                      'Create new patient'
                    )}
              </CustomButton>
              <div style={{ height: '1px', border: `1px solid ${myTheme.palette.primary}` }} />
            </div>
            {showPatientForm && (
              <FormWrapper>
                <GroupedFormField>
                  <CustomTextField
                    label={t('devices:assign_device_modal.form.fields.first_name', 'First Name')}
                    id='firstName'
                    control={control}
                  />
                  <CustomTextField
                    label={t('devices:assign_device_modal.form.fields.last_name', 'Last Name')}
                    id='lastName'
                    control={control}
                  />
                </GroupedFormField>
                <CustomTextField
                  label={t('devices:assign_device_modal.form.fields.email', 'E-mail')}
                  id='email'
                  control={control}
                />
                <GroupedFormField>
                  <CustomPasswordField
                    label={t('devices:assign_device_modal.form.fields.password', 'Password')}
                    id='password'
                    control={control}
                    optional
                  />
                  {password && (
                    <CustomPasswordField
                      label={t(
                        'devices:assign_device_modal.form.fields.retype_password',
                        'Re-type Password'
                      )}
                      id='retypePassword'
                      control={control}
                    />
                  )}
                </GroupedFormField>
              </FormWrapper>
            )}
            <FormButtonsWrapper>
              <CustomButton
                data-testid='cancel-medical-form'
                type='button'
                color='light'
                onClick={handleModalClose}>
                {t('devices:assign_device_modal.form.buttons.cancel', 'Cancel')}
              </CustomButton>
              <Tooltip
                title={
                  isAlreadyAssignedPatientSelected
                    ? t(
                        'devices:assign_device_modal.form.buttons.assign_device.disabled.tooltip1',
                        'Selected patient is already assigned to this device.'
                      )
                    : null
                }>
                <span>
                  <CustomButton
                    data-testid='submit-patient-form'
                    type='submit'
                    disabled={isAlreadyAssignedPatientSelected}
                    loading={isLoadingUpdate || isLoadingAddPatient}>
                    {t('devices:assign_device_modal.form.buttons.assign_device', 'Assign device')}
                  </CustomButton>
                </span>
              </Tooltip>
            </FormButtonsWrapper>
          </FormWrapper>
        </form>
      );

    return (
      <ErrorComponent
        message={t('devices:assign_device_modal.error.device_not_found', 'Device not found')}
        allowRedirect
        innerComponent
      />
    );
  };

  return (
    <ModalWrapper title={t('devices:assign_device_modal.form.title', 'Assign Device')}>
      {showForm()}
    </ModalWrapper>
  );
};

export default AssignDeviceModal;
