import { useQueryClient } from '@tanstack/react-query';
import NotesList from 'configurator/components/organisms/NotesList/NotesList';
import {
  CONFIG_ENTRY_NOTES_KEY,
  useConfigEntryNotes,
  useCreateConfigNote,
  useDeleteConfigNote
} from 'configurator/hooks/api/useDevice';
import { ConfigNotesSortOptions } from 'configurator/api/device/device.types';
import { SortDirs } from 'configurator/utils/types';
import { useTranslation } from 'react-i18next';
import ModalWrapper from 'components/Modals/ModalWrapper';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';

type ShowNotesModalProps = {
  handleClose: Function;
  deviceId: number;
  configId: number;
};

const ShowNotesModal = ({ handleClose, deviceId, configId }: ShowNotesModalProps) => {
  const { t } = useTranslation();
  const { result: notes } = useConfigEntryNotes({
    deviceId,
    configId,
    params: { sortby: ConfigNotesSortOptions.date, sortdir: SortDirs.desc }
  });
  const { mutateAsync: createConfigNote, isLoading: isLoadingCreateNote } = useCreateConfigNote();
  const { mutateAsync: deleteConfigNote } = useDeleteConfigNote();
  const queryClient = useQueryClient();

  const handleAddNote = async ({ note, type }) => {
    await createConfigNote({ deviceId, configId: Number(configId), data: { note, type } });
    queryClient.invalidateQueries([CONFIG_ENTRY_NOTES_KEY]);
  };

  const handleDeleteNote = async ({ noteId }) => {
    await deleteConfigNote({ deviceId, configId: Number(configId), noteId });
    queryClient.invalidateQueries([CONFIG_ENTRY_NOTES_KEY]);
  };

  return (
    <ModalWrapper title={t('configurator:component.show_notes.header', 'Notes')}>
      <NotesList
        notes={notes}
        handleAdd={handleAddNote}
        handleDelete={handleDeleteNote}
        isLoadingAddNote={isLoadingCreateNote}
      />
      <FormButtonsWrapper>
        <CustomButton>{t('common:common.cancel', 'Cancel')}</CustomButton>
      </FormButtonsWrapper>
    </ModalWrapper>
  );
};

export default ShowNotesModal;
