import React, { useEffect, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DeviceEntry } from '../../api/devices/device.types';
import { CircularProgress, Modal } from '@mui/material';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import { ModalStyle } from './styles';
import FormButtonsWrapper from '../FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';
import FormComboBox from 'components/FormFields/FormComboBox';
import { useSessionInitialization } from 'hooks/useSessionInitialization';
import { ReactComponent as WarningIcon } from 'assets/warning_icon_session.svg';
import ModalWrapper from 'components/Modals/ModalWrapper';

const SelectDeviceBox = styled.div`
  margin-top: ${(props) => props.theme.dimensions.spacing}px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const InnerWrapper = styled.div`
  color: ${({ theme }) => theme.palette.subtleText};

  p {
    text-align: left;
    margin: 0 auto;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  span {
    font-size: 40px;
  }
`;

const ErrorIcon = styled(IconWrapper)`
  span {
    color: red;
  }
`;

const SuccessIcon = styled(IconWrapper)`
  span {
    color: ${({ theme }) => theme.palette.success2};
  }
`;

const ErrorHeader = styled.h2`
  font-weight: bold;
  text-align: center;
  margin-bottom: 22px;
  margin-top: 18px;
  color: ${({ theme }) => theme.palette.subtleText};
`;

type StartLiveSessionModalProps = {
  patientId: number;
  isModalOpen: boolean;
};

type LiveSessionModalStep = {
  handleClose: any;
  handleAccept: any;
  disabled: boolean;
  devices: DeviceEntry[];
};

export type LiveSessionAction = 'reconnect' | 'session_already_exists' | 'close' | 'start';

const ModalMessageStartSession = ({
  handleClose,
  handleAccept,
  disabled,
  devices = []
}: LiveSessionModalStep) => {
  const { t } = useTranslation('remote_session');
  const [selectedDevice, setSelectedDevice] = useState(devices[0]);
  const filteredDevices = devices.filter((device: DeviceEntry) => device.amputee_id !== null);
  const handleChange = (event) => {
    setSelectedDevice(event);
  };
  return (
    <>
      {filteredDevices.length > 0 && (
        <>
          <p>
            {t(
              'remote_session:modal.start_session.prompt',
              'Are you sure you want to start a session?'
            )}
          </p>
          <br />
          <p>
            {t(
              'remote_session:modal.start_session.notification',
              'Push notification will be sent to the patient on mobile app with request to confirm the session. After confirming it, you will get access to his configuration.'
            )}
          </p>
        </>
      )}
      {filteredDevices.length === 0 && (
        <>
          <p>
            {t(
              'remote_session:modal.start_session.no_device',
              'Sorry. You don&apos;t have any device assign to this account'
            )}
          </p>
        </>
      )}
      {filteredDevices.length > 0 && (
        <SelectDeviceBox>
          <FormComboBox
            id='serial'
            label={t('remote_session:modal.form.choose_device', 'Choose device:')}
            options={filteredDevices}
            optionLabel='serial'
            value={selectedDevice}
            onChange={handleChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </SelectDeviceBox>
      )}
      <FormButtonsWrapper>
        <CustomButton color='light' disabled={disabled} onClick={handleClose}>
          {t('remote_session:modal.buttons.cancel', 'Cancel')}
        </CustomButton>
        <CustomButton
          onClick={() => handleAccept(selectedDevice)}
          disabled={Boolean(!selectedDevice) || disabled}>
          {t('remote_session:modal.buttons.start', 'Start')}
        </CustomButton>
      </FormButtonsWrapper>
    </>
  );
};

export enum SessionInitializationStatus {
  success = 'success',
  waiting = 'waiting',
  error = 'error'
}

const successMessage = () => (
  <p style={{ textAlign: 'center' }}>
    <Translation ns='remote_session'>
      {(t) => {
        return t(
          'remote_session:modal.initialize_session.success.message',
          'You can now start a session with patient.'
        );
      }}
    </Translation>
  </p>
);
const waitingMessage = () => (
  <p style={{ textAlign: 'center' }}>
    <Translation ns='remote_session'>
      {(t) => {
        return t(
          'remote_session:modal.initialize_session.waiting.message',
          'Waiting for patient approval. Please, don’t refresh a page...'
        );
      }}
    </Translation>
  </p>
);
const errorMessage = () => (
  <>
    <p style={{ textAlign: 'center', marginTop: '16px' }}>
      <Translation ns='remote_session'>
        {(t) => {
          return t(
            'remote_session:modal.initialize_session.error.message',
            'Failed to connect. Contact the patient to see, if he or she has accepted the invitation and then repeat the request.'
          );
        }}
      </Translation>
    </p>
  </>
);

const messageByStatus = {
  [SessionInitializationStatus.waiting]: {
    message: waitingMessage,
    icon: (
      <LoaderWrapper>
        <CircularProgress />
      </LoaderWrapper>
    ),
    actions: (handleClose: any) => (
      <FormButtonsWrapper>
        <CustomButton color='light' onClick={() => handleClose('close')}>
          <Translation ns='remote_session'>
            {(t) => {
              return t(
                'remote_session:modal.initialize_session.waiting.close_session_button',
                'Close session'
              );
            }}
          </Translation>
        </CustomButton>
      </FormButtonsWrapper>
    )
  },
  [SessionInitializationStatus.success]: {
    message: successMessage,
    icon: (
      <SuccessIcon>
        <span className='k-icon k-i-check-circle' />
      </SuccessIcon>
    ),
    actions: null
  },
  [SessionInitializationStatus.error]: {
    message: errorMessage,
    icon: (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <WarningIcon />
      </div>
    ),
    actions: (handleClose: any, handleAccept: any) => (
      <FormButtonsWrapper>
        <CustomButton onClick={() => handleClose('close')} color='light'>
          <Translation ns='remote_session'>
            {(t) => {
              return t('remote_session:modal.initialize_session.error.cancel_button', 'Cancel');
            }}
          </Translation>
        </CustomButton>
        <CustomButton onClick={() => handleAccept('reconnect')}>
          <Translation ns='remote_session'>
            {(t) => {
              return t('remote_session:modal.initialize_session.error.retry_button', 'Try again');
            }}
          </Translation>
        </CustomButton>
      </FormButtonsWrapper>
    )
  }
};

const ModalMessageInitializeSession = ({
  handleAccept,
  handleClose,
  status,
  ...props
}: {
  handleAccept: any;
  handleClose: any;
  status: SessionInitializationStatus;
}) => {
  const currentStatus = messageByStatus[status];
  return (
    <div {...props}>
      {currentStatus.icon && currentStatus.icon}
      <div>{currentStatus.message()}</div>
      {currentStatus?.actions && currentStatus?.actions(handleClose, handleAccept)}
    </div>
  );
};

const StartLiveSessionModal = ({ patientId, isModalOpen }: StartLiveSessionModalProps) => {
  const { t } = useTranslation('remote_session');
  const {
    handleConnectionActions,
    devices,
    createSessionIsLoading,
    step,
    startSessionIsAvailable,
    liveSession,
    handleInitSession: handleAcceptProps,
    handleSessionModalClose: handleClose,
    isLoadingSession
  } = useSessionInitialization({ patientId });

  const startActionIsDisabled = !startSessionIsAvailable || createSessionIsLoading;
  const session = Boolean(liveSession) ?? false;

  const [isStart, setIsStart] = useState(false);

  const handleAccept = (selectedDevice: DeviceEntry) => {
    handleAcceptProps(selectedDevice).then((result: boolean) => {
      if (result) {
        setIsStart(true);
      }
    });
  };

  const handleReconnect = () => {
    handleConnectionActions('session_already_exists');
    setIsStart(true);
  };

  useEffect(() => {
    if (isStart && step === SessionInitializationStatus.success) handleConnectionActions('start');
  }, [isStart, step, handleConnectionActions]);

  const required = devices && !isLoadingSession;

  return (
    <ModalWrapper title={t('remote_session:modal.title', 'Start Remote session')}>
      {required ? (
        <InnerWrapper>
          {!session && !isStart && (
            <ModalMessageStartSession
              devices={devices}
              disabled={startActionIsDisabled || createSessionIsLoading}
              handleAccept={handleAccept}
              handleClose={handleClose}
            />
          )}
          {isStart && (
            <ModalMessageInitializeSession
              handleAccept={handleConnectionActions}
              handleClose={handleConnectionActions}
              status={step}
            />
          )}
          {!isStart && session && (
            <>
              {step === SessionInitializationStatus.waiting && (
                <ModalMessageInitializeSession
                  handleAccept={handleConnectionActions}
                  handleClose={handleConnectionActions}
                  status={step}
                />
              )}
              {step === SessionInitializationStatus.success && (
                <div>
                  <SuccessIcon>
                    <span className='k-icon k-i-check-circle' />
                  </SuccessIcon>
                  <p style={{ textAlign: 'center' }}>
                    {t(
                      'remote_session:modal.initialize_session.existing_session.message',
                      'Your session is already created'
                    )}
                  </p>
                  <FormButtonsWrapper>
                    {!startActionIsDisabled && (
                      <CustomButton
                        disabled={startActionIsDisabled}
                        color='light'
                        onClick={() => handleReconnect()}>
                        {t(
                          'remote_session:modal.initialize_session.existing_session.start_config_button',
                          'Start configuration'
                        )}
                      </CustomButton>
                    )}
                    <CustomButton
                      disabled={createSessionIsLoading}
                      onClick={() => handleConnectionActions('close')}>
                      {t(
                        'remote_session:modal.initialize_session.existing_session.close_session_button',
                        'Close session'
                      )}
                    </CustomButton>
                  </FormButtonsWrapper>
                </div>
              )}
            </>
          )}
        </InnerWrapper>
      ) : (
        <LoaderWrapper>
          <CircularProgress sx={{ margin: '0 auto' }} />
        </LoaderWrapper>
      )}
    </ModalWrapper>
  );
};

export default StartLiveSessionModal;
