/* eslint-disable react/jsx-key */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { MenuItem, TextField, Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import {
  TEMPLATE_NOTES_KEY,
  useCreateTemplateNote,
  useDeleteTemplateNote,
  useDeviceConfigTemplates,
  useTemplateNotes
} from 'configurator/hooks/api/useDevice';
import InnerCard from 'configurator/components/atoms/Card/InnerCard';
import { Table } from 'configurator/components/atoms/Table/Table';
import Pagination from 'configurator/components/atoms/Pagination/Pagination';
import Divider from 'configurator/components/atoms/Divider/Divider';
import { ReactComponent as Eye } from 'assets/eye-solid.svg';
import { ReactComponent as Add } from 'assets/add.svg';
import { useDeviceManager } from 'configurator/hooks/api/useDeviceManager';
import ImportTemplateModal from 'configurator/modals/ImportTemplateModal';
import { useModal } from 'configurator/hooks/useModal';
import { SortDirs } from 'configurator/utils/types';
import ModalPortal from 'configurator/utils/Modal/ModalPortal';
import NotesList from 'configurator/components/organisms/NotesList/NotesList';
import AddNote from 'configurator/modals/AddNoteModal';
import { findDifferencesImport } from 'configurator/utils/Config/transformConfig';
import useImportButton from 'configurator/hooks/useImportButton';
import { useConfigStore } from 'configurator/reducers/configStore';
import {
  ConfigTemplatesExtendOptions,
  ConfigTemplatesScopeOptions,
  ConfigNotesSortOptions
} from '../../../api/device/device.types';
import { CONFIG_TEMPLATES } from 'constants/routes';
import CustomPageHeader from 'configurator/components/atoms/Typography/CustomHeader';
import CustomButton from 'components/Button/CustomButton';
import { AddTemplateModal } from 'configurator/modals/AddTemplate';
import Card from 'adp-panel/components/Card/Card';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import ModalWrapper from 'components/Modals/ModalWrapper';

export const TextStyled = styled.div`
  max-height: 80px;
  overflow: auto;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;
  flex-wrap: wrap;

  button {
    width: auto;
    display: flex;
    align-items: center;
  }
`;

const ChangeItemWrapper = styled.tr`
  td {
    word-wrap: break-word;

    &:not(:last-child) {
      padding-right: 12px;
    }
  }
`;

const templateOwnerOptions = [
  { id: ConfigTemplatesScopeOptions.me, name: 'Me' },
  { id: ConfigTemplatesScopeOptions.global, name: 'Aether' }
];

const ChangeItem = ({ changeEntry }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { importTemplateConfig, isLoadingDeviceManager } = useDeviceManager();
  const {
    handleClose: handleCloseImportModal,
    handleOpen: handleOpenImportModal,
    isOpen: isOpenImportModal
  } = useModal();
  const { mutateAsync: createTemplateNote, isLoading: isLoadingCreateNote } =
    useCreateTemplateNote();
  const { mutateAsync: deleteTemplateNote } = useDeleteTemplateNote();
  const { handleClose, handleOpen, isOpen } = useModal();
  const {
    handleClose: handleCloseAddNote,
    handleOpen: handleOpenAddNote,
    isOpen: isOpenAddNote
  } = useModal();
  const { common, modes } = useConfigStore((state: any) => ({
    modes: state.config.modes,
    common: state.config.common
  }));
  const queryClient = useQueryClient();
  const importDifferences = findDifferencesImport(common, modes, JSON.parse(changeEntry.config));
  const { importTooltip, disableImportButton } = useImportButton(importDifferences);

  const handlePush = (templateId: number) => {
    navigate(`${CONFIG_TEMPLATES}/${templateId}`);
  };

  const { result: notes } = useTemplateNotes({
    templateId: Number(changeEntry.id),
    // @ts-ignore
    params: { sortby: ConfigNotesSortOptions.date, sortdir: SortDirs.desc }
  });

  const handleImport = async (modeId: number) => {
    await importTemplateConfig(modeId, Number(changeEntry.id));
    handleCloseImportModal();
  };

  const handleAddNote = async ({ note }: any) => {
    await createTemplateNote({ templateId: Number(changeEntry.id), data: { note } });
    queryClient.invalidateQueries([TEMPLATE_NOTES_KEY]);
  };

  const handleDeleteNote = async ({ noteId }: any) => {
    await deleteTemplateNote({ templateId: Number(changeEntry.id), noteId });
    queryClient.invalidateQueries([TEMPLATE_NOTES_KEY]);
  };

  return (
    <>
      {isOpenImportModal && (
        <ImportTemplateModal
          header={t('configurator:component.change_item.import_template', 'Import template')}
          handleAccept={handleImport}
          handleClose={handleCloseImportModal}
          entry={JSON.parse(changeEntry.config)}
          isLoading={isLoadingDeviceManager}
        />
      )}
      {isOpen && (
        <ModalWrapper title={t('configurator:component.change_item.notes', 'Notes')}>
          <NotesList notes={notes} handleDelete={handleDeleteNote} showTypes={false} />
          <FormButtonsWrapper>
            <CustomButton color='light' onClick={handleClose}>
              {t('common:common.cancel', 'Cancel')}
            </CustomButton>
          </FormButtonsWrapper>
        </ModalWrapper>
      )}
      {isOpenAddNote && (
        <ModalPortal>
          <AddNote
            handleClose={handleCloseAddNote}
            handleSubmitNote={handleAddNote}
            isLoading={isLoadingCreateNote}
            showTypes={false}
          />
        </ModalPortal>
      )}
      <ChangeItemWrapper>
        <td>{changeEntry?.name}</td>
        <td>
          <TextStyled>{changeEntry?.description}</TextStyled>
        </td>
        <td>
          <TextStyled>{notes?.[0]?.note}</TextStyled>
        </td>
        <td>{dayjs(changeEntry?.created_at).format('MM.DD.YYYY, HH:mm')}</td>
        <td>
          <ActionsWrapper>
            <Tooltip title={importTooltip()}>
              <CustomButton
                onClick={() => handleOpenImportModal()}
                color='light'
                disabled={disableImportButton()}>
                {t('configurator:component.change_item.import', 'Import')}
              </CustomButton>
            </Tooltip>
            <CustomButton
              onClick={() => handlePush(changeEntry.id)}
              color='light'
              Icon={Eye}
              iconProps={{ width: '14px' }}>
              {t('configurator:component.change_item.see', 'See')}
            </CustomButton>
            {notes?.length > 0 && (
              <CustomButton
                onClick={handleOpen}
                iconProps={{ sx: { width: '18px', height: '18px', padding: 0 } }}
                Icon={StickyNote2OutlinedIcon}>
                {t('configurator:component.change_item.show_notes', 'Show notes')}
              </CustomButton>
            )}
            <CustomButton onClick={handleOpenAddNote} color='light' Icon={Add}>
              {t('configurator:component.change_item.add_note', 'Add note')}
            </CustomButton>
          </ActionsWrapper>
        </td>
      </ChangeItemWrapper>
    </>
  );
};

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ConfigTemplatesComponent = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [ownerSelected, setOwnerSelected] = useState<ConfigTemplatesScopeOptions>(
    ConfigTemplatesScopeOptions.me
  );
  const { result: configTemplates } = useDeviceConfigTemplates({
    params: {
      extend: ConfigTemplatesExtendOptions.author,
      scope: ownerSelected,
      perpage: 10,
      page: currentPage
    }
  });
  const { modes } = useConfigStore((state: any) => ({
    modes: state.config.modes
  }));
  const {
    handleClose: handleCloseTemplateModal,
    handleOpen: handleOpenTemplateModal,
    isOpen
  } = useModal();

  useEffect(() => {
    if (currentPage > configTemplates?.paginator?.last_page) {
      setCurrentPage(configTemplates?.paginator?.last_page);
    }
  }, [configTemplates?.paginator?.last_page]);

  return (
    <>
      {isOpen && <AddTemplateModal handleClose={handleCloseTemplateModal} configModes={modes} />}
      <CustomPageHeader
        header={t('configurator:component.config_templates.header', 'Config Templates')}
        tooltipText={t('configurator:header.config_templates.tooltip', 'Tooltip')}
      />
      <Card>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
          <TextField
            sx={{ width: 150 }}
            label={t('configurator:component.config_templates.owner', 'Owner')}
            id='selected-owner'
            select
            SelectProps={{
              onChange: (e: any) => setOwnerSelected(e.target.value),
              value: ownerSelected
            }}>
            {templateOwnerOptions.map((option) => (
              <MenuItem key={`selected-owner_${option.id}`} value={option.id}>
                {t(
                  `configurator:component.config_templates.owner_${option.name.toLowerCase()}`,
                  option.name
                )}
              </MenuItem>
            ))}
          </TextField>
          <CustomButton Icon={Add} onClick={handleOpenTemplateModal}>
            {t('configurator:component.config_templates.add_new_template', 'Add new template')}
          </CustomButton>
        </div>
        <div>
          <InnerCard>
            <Table>
              <tr>
                <th>{t('configurator:component.config_templates.name', 'Name')}</th>
                <th>{t('configurator:component.config_templates.description', 'Description')}</th>
                <th>{t('configurator:component.config_templates.note', 'Note')}</th>
                <th>{t('configurator:component.config_templates.created_at', 'Created at')}</th>
                <th style={{ width: '28%' }}>
                  {t('configurator:component.config_templates.actions', 'Actions')}
                </th>
              </tr>
              {configTemplates?.items.map((deviceConfigHistoryEntry: any) => (
                <ChangeItem changeEntry={deviceConfigHistoryEntry} />
              ))}
            </Table>
          </InnerCard>
          <Divider margin='20px' />
          <PaginationWrapper>
            <Pagination
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              lastPage={configTemplates?.paginator?.last_page}
            />
          </PaginationWrapper>
        </div>
      </Card>
    </>
  );
};

export default ConfigTemplatesComponent;
