import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import useBluetooth from 'configurator/hooks/bluetooth/useConnect';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import useUnsaved from 'configurator/hooks/useUnsaved';
import { pauseSoundElement, playSoundElement } from 'App';
import { useUiStore } from 'configurator/reducers/uiStore';
import { FETCHING_STATES } from 'configurator/consts/consts';
import { MODALS } from 'configurator/views/Modals';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import ModalWrapper from 'components/Modals/ModalWrapper';
import CustomButton from 'components/Button/CustomButton';

const ParagraphBold = styled.p`
  ${TextS};
`;

type ModalProps = {
  handleClose: Function;
  handleAccept: Function;
};

const DisruptiveDisconnectModal = ({ handleClose, handleAccept }: ModalProps) => {
  const { isUnsaved } = useUnsaved();
  const { t } = useTranslation();
  const { bluetoothConnect } = useBluetooth();
  const { deviceConnected, amputeeId, deviceId } = useDeviceInfoStore((state: any) => ({
    deviceConnected: state.connected,
    amputeeId: state.amputeeId,
    deviceId: state.deviceId
  }));
  const getInitialConfigAPI = useConfigStore((state: any) => state.getInitialConfigAPI);
  const { initialConfigState, isModalOpen } = useUiStore((state) => ({
    initialConfigState: state.initialConfigState,
    isModalOpen: state.isModalOpen
  }));

  useEffect(() => {
    if (isModalOpen(MODALS.firmware)) {
      handleClose();
      return;
    }
    playSoundElement();
  }, []);

  const accepted = async () => {
    pauseSoundElement();
    handleAccept();
    handleClose();
  };

  const denied = async () => {
    localStorage.removeItem(`localSessionBackupConfig-${deviceId}`);
    pauseSoundElement();
    await getInitialConfigAPI();
    handleClose();
  };

  const reconnect = () => {
    pauseSoundElement();
    bluetoothConnect();
    handleClose();
  };

  const canSaveSettings =
    amputeeId && isUnsaved && initialConfigState === FETCHING_STATES.successful;

  const handleClick = () => {
    pauseSoundElement();
    handleClose();
  };

  return (
    <ModalWrapper
      title={t(
        'configurator:component.disruptive_disconnect_modal.device_was_disconnected_unexpectedly',
        'Device was disconnected unexpectedly'
      )}>
      <ParagraphBold>
        {canSaveSettings
          ? t(
              'configurator:component.disruptive_disconnect_modal.changes_not_saved_do_you_want_to_save_them',
              'Changes were not saved, do you want to save them?'
            )
          : t(
              'configurator:component.disruptive_disconnect_modal.device_disconnected_do_you_want_to_reconnect',
              'Device has been disconnected, do you want to reconnect?'
            )}
      </ParagraphBold>
      <FormButtonsWrapper>
        <CustomButton onClick={handleClick} color='light'>
          {t('configurator:component.close_session_modal.cancel', 'Cancel')}
        </CustomButton>

        {canSaveSettings && (
          <CustomButton onClick={denied} color={amputeeId ? 'light' : 'primary'}>
            {t('configurator:component.disruptive_disconnect_modal.no_dont_save', "No, don't save")}
          </CustomButton>
        )}
        {!deviceConnected && (
          <CustomButton onClick={reconnect}>
            {t('configurator:component.disruptive_disconnect_modal.reconnect', 'Reconnect')}
          </CustomButton>
        )}
        {canSaveSettings && (
          <CustomButton onClick={accepted}>
            {t('configurator:component.disruptive_disconnect_modal.yes_save', 'Yes, save')}
          </CustomButton>
        )}
      </FormButtonsWrapper>
    </ModalWrapper>
  );
};

export default DisruptiveDisconnectModal;
