/* eslint-disable no-unused-vars */
import { LoadingButton } from '@mui/lab';
import { TextField, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { NoteTypes } from 'configurator/utils/types';
import ModalWrapper from 'components/Modals/ModalWrapper';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';

const StyledForm = styled.form`
  display: grid;
  gap: 24px;
`;

type AboutProps = {
  handleClose: (event?: React.MouseEvent<HTMLElement>) => void;
  handleSubmitNote: Function;
  showTypes?: boolean;
  isLoading: boolean;
};

type Inputs = {
  note: string;
};

export const NoteLabels = [
  { id: NoteTypes.private, name: 'configurator:component.note.private' },
  { id: NoteTypes.public, name: 'configurator:component.note.visible_for_patient' }
];

const AddNote = ({
  handleClose,
  handleSubmitNote = () => true,
  showTypes = true,
  isLoading
}: AboutProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>();
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState(NoteTypes.private);
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    handleSubmitNote({ note: data.note, type: selectedType });
    handleClose();
  };

  const NoteLabels = [
    {
      id: NoteTypes.private,
      name: t('configurator:component.add_note.private', 'Private')
    },
    {
      id: NoteTypes.public,
      name: t('configurator:component.add_note.visible_for_patient', 'Visible for patient')
    }
  ];

  return (
    <ModalWrapper title={t('configurator:component.add_note.add_note', 'Add note')}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <TextField
          id='note'
          label={''}
          multiline
          required
          placeholder={t('configurator:component.add_note.write_something', 'Write something...')}
          {...register('note', {
            required: t('configurator:component.add_note.field_required', 'This field is required')
          })}
          error={Boolean(errors?.note)}
          helperText={errors?.note?.message}
          rows={6}
        />
        {showTypes && (
          <TextField
            label={t('configurator:component.add_note.type', 'Type')}
            id='selected-type'
            select
            SelectProps={{
              value: selectedType,
              onChange: (e: any) => setSelectedType(e.target.value)
            }}>
            {NoteLabels.map((noteLabel) => (
              <MenuItem key={`selected-type_${noteLabel.id}`} value={noteLabel.id}>
                {noteLabel.name}
              </MenuItem>
            ))}
          </TextField>
        )}
        <FormButtonsWrapper>
          <CustomButton onClick={handleClose} color='light'>
            {t('configurator:component.add_note.cancel', 'Cancel')}
          </CustomButton>
          <LoadingButton type='submit' loading={isLoading}>
            <span>{t('configurator:component.add_note.add_note', 'Add note')}</span>
          </LoadingButton>
        </FormButtonsWrapper>
      </StyledForm>
    </ModalWrapper>
  );
};

export default AddNote;
