import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import useRemoteSession from 'configurator/hooks/useRemoteSession';
import ModalWrapper from 'components/Modals/ModalWrapper';
import CustomButton from 'components/Button/CustomButton';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';

const ParagraphBold = styled.p`
  ${TextS};
`;

type ModalProps = {
  handleClose: any;
};

const ResetAllChangesModal = ({ handleClose }: ModalProps) => {
  const { consumeAllSessionChanges } = useRemoteSession();
  const { t } = useTranslation();
  const accepted = async () => {
    consumeAllSessionChanges();
    handleClose();
  };

  return (
    <ModalWrapper
      title={t('configurator:component.reset_all_changes_modal.reset_changes', 'Reset changes')}>
      <ParagraphBold>
        {t(
          'configurator:component.reset_all_changes_modal.confirm_reset',
          'Are you sure want to reset all changes? This cannot be undone.'
        )}
      </ParagraphBold>
      <FormButtonsWrapper>
        <CustomButton onClick={handleClose} color='light'>
          {t('configurator:component.reset_all_changes_modal.cancel', 'Cancel')}
        </CustomButton>
        <CustomButton onClick={accepted}>
          {t('configurator:component.reset_all_changes_modal.reset_all', 'Reset all')}
        </CustomButton>
      </FormButtonsWrapper>
    </ModalWrapper>
  );
};

export default ResetAllChangesModal;
