import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import { SERVICING } from 'constants/routes';
import ModalWrapper from 'components/Modals/ModalWrapper';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';

const ParagraphBold = styled.p`
  ${TextS};
  margin-top: 30px;
  margin-bottom: 40px;
`;

type ModalProps = {
  handleClose: any;
};

const SessionWasEndedServicing = ({ handleClose }: ModalProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const accepted = async () => {
    handleClose();
    navigate(SERVICING);
  };

  return (
    <ModalWrapper
      title={t(
        'configurator:component.session_was_ended_servicing.device_is_disconnected',
        'Device is disconnected'
      )}>
      <ParagraphBold>
        {t(
          'configurator:component.session_was_ended_servicing.session_over_replacement_form',
          'The local session is over, if there was a replacement of parts, go to the form.'
        )}
      </ParagraphBold>
      <FormButtonsWrapper>
        <CustomButton onClick={handleClose} color='light'>
          {t(
            'configurator:component.session_was_ended_servicing.no_replacement_needed',
            'No replacement was needed'
          )}
        </CustomButton>
        <CustomButton onClick={accepted}>
          {t(
            'configurator:component.session_was_ended_servicing.go_to_replacement_form',
            'Go to replacement form'
          )}
        </CustomButton>
      </FormButtonsWrapper>
    </ModalWrapper>
  );
};

export default SessionWasEndedServicing;
