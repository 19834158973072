import { LoadingButton } from '@mui/lab';
import { CustomVerificationNumberCode } from 'adp-panel/components/CustomVerificationCode/CustomVerificationCode';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import useCountDown from 'adp-panel/hooks/useCountDown';
import CustomButton from 'components/Button/CustomButton';
import { FormWrapper } from 'components/FormFields/commonStyles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVerifyPhone } from 'hooks/useAuthentication';
import { ME_QUERY_KEY, useChangeUserPhone, USER_QUERY_KEY } from '../../hooks/api/useUsers';
import useUserData from '../../../hooks/useUserData';
import { useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { Link, Typography } from '@mui/material';
import ModalWrapper from 'components/Modals/ModalWrapper';

type VerifyPhoneModalProps = {
  handleClose: any;
};

const VerifyPhoneModal = ({ handleClose }: VerifyPhoneModalProps) => {
  const { t } = useTranslation();
  const [verificationCode, setVerificationCode] = useState<string | undefined>(undefined);
  const { mutateAsync: verifyCode, isLoading: isLoadingVerify, isError } = useVerifyPhone();
  const { mutateAsync: changePhone, isLoading: isLoadingChangePhone } = useChangeUserPhone();
  const { data: userData } = useUserData();
  const {
    time: resendRemainingTime,
    start: startCountDown,
    reset: resetCountDown
  } = useCountDown({ initialTime: 60, autoStart: true });
  const queryClient = useQueryClient();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const code = verificationCode;

    if (undefined === code) return;

    setVerificationCode(undefined);

    try {
      await verifyCode({ code: Number(code) });
      handleClose();
      await queryClient.invalidateQueries([ME_QUERY_KEY]);
      await queryClient.invalidateQueries([USER_QUERY_KEY]);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const resendCode = async () => {
    await changePhone({
      userId: userData.id,
      phone: userData.phone,
      phone_country: userData.phone_country,
      resend_code: true
    });
    resetCountDown();
    startCountDown();
  };

  const handleVerificationCodeChange = (e: string) => {
    setVerificationCode(e);
  };

  return (
    <ModalWrapper title={t('common:verify_phone_modal.title', 'Verify Phone')}>
      <form style={{ width: '100%' }} onSubmit={handleSubmit}>
        <FormWrapper>
          <Typography sx={{ color: '#344054', fontSize: '14px' }} mb={1}>
            {t(
              'common:verify_phone_modal.description',
              'To verify your phone number, we have sent you a 6-digit code. Please enter it below.'
            )}
          </Typography>
          <CustomVerificationNumberCode
            error={isError}
            onChange={handleVerificationCodeChange}
            value={verificationCode}
          />
          <Typography variant='subtitle2' align='center'>
            {resendRemainingTime > 0 || isLoadingChangePhone ? (
              <Typography
                style={{
                  textDecoration: 'none',
                  color: '#253786',
                  fontSize: '14px',
                  fontWeight: 600
                }}>
                {t('common:verify_phone_modal.resend_code', {
                  defaultValue: 'Resend Code {{time}}',
                  time: resendRemainingTime > 0 ? resendRemainingTime : ''
                })}
              </Typography>
            ) : (
              <Link
                style={{
                  textDecoration: 'none',
                  color: '#253786',
                  fontSize: '14px',
                  fontWeight: 600
                }}
                onClick={() => resendCode()}
                href='#'>
                {t('common:verify_phone_modal.resend_code', {
                  defaultValue: 'Resend Code {{time}}',
                  time: resendRemainingTime > 0 ? resendRemainingTime : ''
                })}
              </Link>
            )}
          </Typography>
          <FormButtonsWrapper>
            <CustomButton
              data-testid='cancel-verify-phone'
              type='button'
              color='light'
              onClick={handleClose}>
              {t('common:verify_phone_modal.buttons.cancel', 'Cancel')}
            </CustomButton>
            <LoadingButton
              disabled={String(verificationCode)?.length !== 6}
              data-testid='submit-verify-phone-form'
              type='submit'
              loading={isLoadingVerify}>
              <span>{t('common:verify_phone_modal.buttons.verify', 'Verify')}</span>
            </LoadingButton>
          </FormButtonsWrapper>
        </FormWrapper>
      </form>
    </ModalWrapper>
  );
};

export default VerifyPhoneModal;
