import CustomButton from 'components/Button/CustomButton';
import { useTranslation } from 'react-i18next';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Box } from '@mui/material';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import ModalWrapper from '../../../components/Modals/ModalWrapper';

const InviteNewPatientSuccessModal = ({ handleModalClose }) => {
  const { t } = useTranslation();

  return (
    <ModalWrapper title={t('patients:invite.success.title', 'Invite new Patient')}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '30px'
        }}>
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#DCFAE6',
            borderRadius: '50%',
            padding: '10px',
            height: '60px',
            width: '60px'
          }}>
          <CheckCircleOutlinedIcon
            sx={{
              color: '#079455',
              fontSize: 30
            }}
          />
        </Box>
        <h2 style={{ margin: '30px 0' }}>
          {t('patients:invite.success.info', 'The Patient have been successfully invited.')}
        </h2>
        <h3 style={{ margin: '0 0', textAlign: 'center' }}>
          {t('patients:invite.success.link.info', 'Invitation link is valid for 24 hours.')}
        </h3>
        <FormButtonsWrapper>
          <CustomButton
            data-testid='cancel-medical-form'
            type='button'
            color='light'
            onClick={handleModalClose}>
            {t('patients:invite.success.close', 'Close')}
          </CustomButton>
        </FormButtonsWrapper>
      </div>
    </ModalWrapper>
  );
};

export default InviteNewPatientSuccessModal;
