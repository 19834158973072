import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ModalWrapper from 'components/Modals/ModalWrapper';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 16px;
`;

type DeleteModalProps = {
  handleClose: any;
  handleAccept: any;
  text: string;
  id: string;
  header: string;
  isLoading?: boolean;
};

const DeleteModal = ({
  handleClose,
  handleAccept,
  text,
  id,
  header,
  isLoading = false
}: DeleteModalProps) => {
  const { t } = useTranslation();
  return (
    <ModalWrapper title={header}>
      <div>
        <p>
          {text}
          <strong>{id}</strong>?{' '}
          {t(
            'configurator:component.delete_modal.action_cannot_be_undone',
            'This action cannot be undone.'
          )}
        </p>
      </div>
      <FormButtonsWrapper>
        <CustomButton onClick={handleClose} color='light'>
          {t('configurator:component.delete_modal.cancel', 'Cancel')}
        </CustomButton>
        <LoadingButton color='error' onClick={handleAccept} loading={isLoading}>
          {t('configurator:component.delete_modal.delete', 'Delete')}
        </LoadingButton>
      </FormButtonsWrapper>
    </ModalWrapper>
  );
};

export default DeleteModal;
