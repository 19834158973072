/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import ModalWrapper from 'components/Modals/ModalWrapper';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';

const ParagraphBold = styled.p`
  ${TextS};
  margin-bottom: 20px;
`;

type ImmediateSendingModeChangeModalProps = {
  handleClose: (event?: React.MouseEvent<HTMLElement>) => void;
  handleAccept: Function;
  handleSaveWithoutChanges: () => void;
  isLoading: boolean;
};

type Inputs = {
  note: string;
};

const ImmediateSendingModeChangeModal = ({
  handleClose,
  handleAccept,
  handleSaveWithoutChanges,
  isLoading
}: ImmediateSendingModeChangeModalProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    handleAccept(data);
  };

  return (
    <ModalWrapper
      title={t(
        'configurator:component.immediate_sending_mode_change_modal.immediate_sending_mode_change',
        'Immediate sending mode change'
      )}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ParagraphBold>
          {t(
            'configurator:component.immediate_sending_mode_change_modal.recommend_saving_settings',
            'Before changing this setting, we recommend saving the current settings, otherwise they may be lost.'
          )}
        </ParagraphBold>
        <TextField
          fullWidth
          id='description'
          label={t(
            'configurator:component.immediate_sending_mode_change_modal.description',
            'Description'
          )}
          multiline
          required
          placeholder={t(
            'configurator:component.immediate_sending_mode_change_modal.describe_the_change',
            'Describe the change'
          )}
          {...register('note', {
            required: t(
              'configurator:component.immediate_sending_mode_change_modal.field_required',
              'This field is required.'
            )
          })}
          error={Boolean(errors?.note)}
          helperText={errors?.note?.message}
          rows={6}
        />
        <FormButtonsWrapper>
          <CustomButton onClick={handleClose} color='light'>
            {t('configurator:component.immediate_sending_mode_change_modal.cancel', 'Cancel')}
          </CustomButton>
          <LoadingButton onClick={handleSaveWithoutChanges} loading={isLoading} color='lightBlue'>
            <span>
              {t(
                'configurator:component.immediate_sending_mode_change_modal.continue_without_saving_changes',
                'Continue without saving changes'
              )}
            </span>
          </LoadingButton>
          <LoadingButton type='submit' loading={isLoading}>
            <span>
              {t(
                'configurator:component.immediate_sending_mode_change_modal.save_and_change',
                'Save and change'
              )}
            </span>
          </LoadingButton>
        </FormButtonsWrapper>
      </form>
    </ModalWrapper>
  );
};

export default ImmediateSendingModeChangeModal;
