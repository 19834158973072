/* eslint-disable no-unused-vars */
import { useTranslation } from 'react-i18next';
import DefaultLayout from '../../layouts/DefaultLayout';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DeviceImg from 'assets/tripod-open.png';
import DeviceRightImg from 'assets/tripod-open-right.png';
import {
  useDeviceConfig,
  useDeviceDelete,
  useDeviceHistory,
  useDeviceInfo
} from '../../hooks/api/useDevices';
import { useUsers } from '../../hooks/api/useUsers';
import { useModal } from '../../hooks/api/useModal';
import { RoleEnum, UsersQueryParams } from '../../api/users/users.types';
import ConfirmDeleteModal, { ModalMessageDevice } from '../../components/Modals/ConfirmDeleteModal';
import { DetailsLink } from '../../components/Links/Links';
import { SuccessButton } from '../../components/ActionButton/ActionButton';
import React, { useEffect, useState } from 'react';
import { ModesEntry, ModesExtendOptions, ModesQueryParams } from '../../api/modes/modes.types';
import { useDeviceModes, useUpdateMode } from '../../hooks/api/useModes';
import { CONFIGURATOR_URL } from '../../constants/config';
import DeviceUsageTab from '../DeviceUsage/DeviceUsageTab';
import { ReactComponent as DeleteSvg } from 'assets/delete.svg';
import { ReactComponent as CogSvg } from 'assets/cog_configure.svg';
import { ButtonsWrapper, StyledCard } from './styled';
import { DeviceExtendOptions, DevicesQueryParams } from '../../api/devices/device.types';
import { userHasPermissions } from '../../utils/permissionUtils';
import * as Sentry from '@sentry/react';
import RepairGrid from './Repair';
import { userHasRole } from '../../hoc/useCanAccess';
import { DEVICE_CONFIG_PERMISSIONS, DEVICES_PERMISSIONS } from '../../constants/rolesPermissions';
import '../Patients/styles.css';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { useQueryClient } from '@tanstack/react-query';
import { ErrorComponent } from 'adp-panel/components/Error/ErrorComponent';
import useUserData from 'hooks/useUserData';
import { UserRoles } from 'types';
import { CLINICIANS, DEVICE, DEVICE_EDIT, PATIENTS } from 'constants/routes';
import ConfirmationLoader from 'adp-panel/layouts/ConfirmationLoader';
import { useSessionStart } from 'hooks/useSessionStart';
import { CircularProgress, Tab, Tabs, Typography } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import { ReactComponent as TrashSvg } from 'assets/trash-icon.svg';
import { ReactComponent as EditSvg } from 'assets/edit-icon.svg';
import { TabsContainer } from '../Patients/styled/PatientTabs.styled';
import { CustomTabPanel } from 'components/TabPanel/CustomTabPanel';
import Card from 'adp-panel/components/Card/Card';
import MultipleItemsLinks from 'components/MultipleItemsLinks/MultipleItemsLinks';
import MultipleItemsModal from 'adp-panel/components/Modals/PatientsModal';
import LoaderWrapper from 'components/Loader/Loader';

const DeviceDetails = () => {
  const { t } = useTranslation();
  let { deviceId } = useParams() as any;
  deviceId = Number(deviceId);
  const client = useQueryClient();
  const { rolesByName, data: user } = useUserData();
  const [selected, setSelected] = useState(0);
  const [selectedDeviceClinicians, setSelectedDeviceClinicians] = useState<any>(null);
  const navigate = useNavigate();
  const isClinicianSupportAccount = userHasRole(user, RoleEnum.clinicianSupport);
  const queryParamsDevices: DevicesQueryParams = {
    extend: [DeviceExtendOptions.model, DeviceExtendOptions.clinicians, DeviceExtendOptions.amputee]
  };
  const { result: deviceInfo, isLoading } = useDeviceInfo(deviceId, true, queryParamsDevices);

  const {
    isOpen: isModalOpen,
    handleOpen: handleCliniciansModalOpen,
    handleClose: handleModalClose
  } = useModal();

  /* const handleEdit = () => {
    navigate(DEVICE_EDIT, { state: { detail: deviceInfo } });
  }; */

  const { mutateAsync: updateMode } = useUpdateMode();
  const userQueryParams: UsersQueryParams = {
    roles: UserRoles.amputee
  };
  const { result: amputee } = useUsers(userQueryParams);
  const modesQueryParams: ModesQueryParams = {
    extend: ModesExtendOptions.deviceAmputee
  };
  const configModes = useDeviceModes(
    deviceId,
    !!deviceId && userHasPermissions(DEVICE_CONFIG_PERMISSIONS.DETAILS, rolesByName),
    modesQueryParams
  );
  const deviceConfigs = useDeviceConfig(
    deviceId,
    !!deviceId && userHasPermissions(DEVICE_CONFIG_PERMISSIONS.DETAILS, rolesByName)
  );

  const { mutateAsync: handleSubmitDeleteDevice, isLoading: isLoadingDeleteDevice } =
    useDeviceDelete();
  const { handleOpenLocalSession } = useSessionStart();

  const handleSelect = (e: any) => {
    setSelected(e.selected);
  };

  const submitEditForm = async (formParams: any, currentItem: ModesEntry) => {
    try {
      if (currentItem) {
        // @ts-ignore
        await updateMode({
          name: formParams.mode_name,
          deviceId: currentItem.device_id,
          modeId: currentItem.id
        });
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log('e', e);
    }
  };

  const handleEditOnConfigurator = (item: ModesEntry, modeNumber: number) => {
    if (!item) {
      return;
    }
    window.location.replace(`${CONFIGURATOR_URL}/device?deviceId=${deviceId}&modeId=${modeNumber}`);
  };

  const {
    isOpen: isDeleteModalOpen,
    handleOpen: handleDeleteModalOpen,
    handleClose: handleDeleteModalClose
  } = useModal();

  const deleteDevice = async () => {
    await handleSubmitDeleteDevice(deviceInfo.id);
    handleDeleteModalClose();
    navigate(DEVICE);
  };

  const handleOpenClinicians = (clinicians) => {
    setSelectedDeviceClinicians(clinicians);
    handleCliniciansModalOpen();
  };

  if (!deviceInfo && !isLoading)
    return (
      <ErrorComponent
        message={t(
          'devices:device_details.errors.device_not_found',
          "The device no longer exists or you don't have access to it."
        )}
        allowRedirect
      />
    );

  const Details = (
    <div>
      {isModalOpen && (
        <MultipleItemsModal
          handleClose={handleModalClose}
          items={selectedDeviceClinicians}
          title='Clinicians'
          link={CLINICIANS}
        />
      )}
      {isDeleteModalOpen && (
        <ConfirmDeleteModal
          handleClose={handleDeleteModalClose}
          handleAccept={deleteDevice}
          isLoading={isLoadingDeleteDevice}
          message={t('devices:devices_list.table.delete.message', {
            defaultValue: 'Do you want to delete device (ID: {{value}})?',
            value: deviceInfo.id
          })}
        />
      )}
      {isLoading ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <Card>
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <img
              src={deviceInfo?.model?.orientation === 'left' ? DeviceImg : DeviceRightImg}
              alt={t('devices:device_details.image.alt', 'Device image')}
              style={{
                width: '100px',
                height: '100px',
                backgroundColor: '#F2F4F7',
                borderRadius: '50%',
                padding: '10px'
              }}
            />
            <div style={{ height: '56px' }}>
              <Typography variant='h5'>{deviceInfo?.serial}</Typography>
              <Typography sx={{ color: '#475467' }}>{deviceInfo?.model?.name}</Typography>
            </div>
            <div
              style={{
                height: '56px',
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '10%'
              }}>
              <Typography sx={{ color: '#475467' }}>Clinicians</Typography>
              <MultipleItemsLinks
                maxItems={1}
                items={deviceInfo?.clinicians}
                link={CLINICIANS}
                handleOpen={handleOpenClinicians}
              />
            </div>
            <div
              style={{
                height: '56px',
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '10%'
              }}>
              <Typography sx={{ color: '#475467' }}>Patient</Typography>
              <DetailsLink to={`${PATIENTS}/${deviceInfo?.amputee_id}`}>
                {deviceInfo?.amputee?.name}
              </DetailsLink>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginTop: '16px' }}>
            {userHasPermissions(DEVICES_PERMISSIONS.CREATE, rolesByName) && (
              <>
                <CustomButton
                  data-testid='configurator'
                  onClick={() =>
                    handleOpenLocalSession(deviceInfo?.serial, deviceInfo?.bluetooth_id, deviceId)
                  }
                  Icon={CogSvg}>
                  {t('devices:device_details.buttons.configurator', 'Configurator')}
                </CustomButton>
                <CustomButton
                  color='light'
                  data-testid='configurator'
                  onClick={() => navigate(DEVICE_EDIT, { state: { detail: deviceInfo.id } })}
                  Icon={EditSvg}>
                  Edit device
                </CustomButton>
                <CustomButton
                  data-testid='configurator'
                  color='light'
                  onClick={handleDeleteModalOpen}
                  Icon={TrashSvg}>
                  Delete device
                </CustomButton>
              </>
            )}
          </div>
          <Card style={{ marginTop: '16px' }}>
            <TabsContainer>
              <Tabs value={0}>
                <Tab
                  label={t('patients:details.tabs.device_usage', 'Device usage')}
                  id='patient-tab-0'
                  aria-controls='patient-tabpanel-0'
                />
              </Tabs>

              <CustomTabPanel value={0} index={0}>
                {deviceInfo !== undefined && <DeviceUsageTab devices={[deviceInfo]} />}
              </CustomTabPanel>
            </TabsContainer>
          </Card>
        </Card>
      )}
    </div>
  );

  return <DefaultLayout>{Details}</DefaultLayout>;
};

export default DeviceDetails;
