/* eslint-disable no-unused-vars */
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SERVICE_MENU } from 'constants/routes';
import { useUiStore } from 'configurator/reducers/uiStore';
import ModalWrapper from 'components/Modals/ModalWrapper';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';

const AuthorizeServiceModal = ({ handleClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const setItemUiStore = useUiStore((state) => state.setItemUiStore);
  const onSubmit = async () => {
    setItemUiStore('isAuthorized', true);
    handleClose();
    navigate(SERVICE_MENU);
  };

  return (
    <ModalWrapper
      title={t('configurator:component.authorize_service_modal.authorization', 'Authorization')}>
      <form onSubmit={handleSubmit(() => onSubmit())} style={{ display: 'grid', gap: '16px' }}>
        <TextField
          id='user'
          label={t('configurator:component.authorize_service_modal.user', 'User')}
          fullWidth
          required
          {...register('user', {
            required: t(
              'configurator:component.authorize_service_modal.field_required',
              'This field is required'
            ),
            validate: (val: string) => {
              if (val !== 'clinician') {
                return t(
                  'configurator:component.authorize_service_modal.user_or_password_incorrect',
                  'User or password is incorrect'
                );
              }
            }
          })}
          error={Boolean(errors?.user)}
          helperText={errors?.user?.message}
        />
        <TextField
          id='password'
          label='Password'
          fullWidth
          required
          {...register('password', {
            required: t(
              'configurator:component.authorize_service_modal.field_required',
              'This field is required'
            ),
            validate: (val: string) => {
              if (val !== 'Zeus2021') {
                return t(
                  'configurator:component.authorize_service_modal.user_or_password_incorrect',
                  'User or password is incorrect'
                );
              }
            }
          })}
          error={Boolean(errors?.password)}
          helperText={errors?.password?.message}
        />
        <FormButtonsWrapper>
          <CustomButton type='button' color='light' onClick={handleClose}>
            {t('common:common.cancel', 'Cancel')}
          </CustomButton>
          <CustomButton type='submit'>
            {t('configurator:component.authorize_service_modal.enter', 'Enter')}
          </CustomButton>
        </FormButtonsWrapper>
      </form>
    </ModalWrapper>
  );
};

export default AuthorizeServiceModal;
