import { LoadingButton } from '@mui/lab';
import { CustomVerificationNumberCode } from 'adp-panel/components/CustomVerificationCode/CustomVerificationCode';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import useCountDown from 'adp-panel/hooks/useCountDown';
import CustomButton from 'components/Button/CustomButton';
import { FormWrapper } from 'components/FormFields/commonStyles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMfaSend, useMfaVerify } from 'hooks/useAuthentication';
import { ME_QUERY_KEY, USER_QUERY_KEY } from '../../hooks/api/useUsers';
import { useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { Link, Typography } from '@mui/material';
import { MfaOptions } from 'adp-panel/api/authentication/authentication.types';
import ModalWrapper from 'components/Modals/ModalWrapper';

type VerifyEmailModalProps = {
  handleClose: any;
  setEmailVerified: any;
};

const VerifyEmailModal = ({ handleClose, setEmailVerified }: VerifyEmailModalProps) => {
  const { t } = useTranslation();
  const [verificationCode, setVerificationCode] = useState<string | undefined>(undefined);
  const { mutateAsync: verifyCode, isLoading: isLoadingVerify } = useMfaVerify();
  const { mutateAsync: sendMfaCode, isLoading: isLoadingMfaSend } = useMfaSend();
  const {
    time: resendRemainingTime,
    start: startCountDown,
    reset: resetCountDown
  } = useCountDown({ initialTime: 60, autoStart: true });
  const queryClient = useQueryClient();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const code = verificationCode;

    if (undefined === code) return;

    setVerificationCode(undefined);

    try {
      await verifyCode({ channel: MfaOptions.email, code: Number(code) });
      setEmailVerified(true);
      handleClose();
      await queryClient.invalidateQueries([ME_QUERY_KEY]);
      await queryClient.invalidateQueries([USER_QUERY_KEY]);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const resendCode = async () => {
    sendMfaCode({ channel: MfaOptions.email });
    resetCountDown();
    startCountDown();
  };

  const handleVerificationCodeChange = (e: string) => {
    setVerificationCode(e);
  };

  return (
    <ModalWrapper title={t('common:verify_email_modal.title', 'Verify Email')}>
      <form onSubmit={handleSubmit}>
        <FormWrapper>
          <Typography mb={1}>
            {t(
              'common:verify_email_modal.description',
              'To verify your email, we have sent you a 6-digit code. Please enter it below.'
            )}
          </Typography>
          <CustomVerificationNumberCode
            fullWidth
            onChange={handleVerificationCodeChange}
            value={verificationCode}
          />
          <Typography variant='subtitle2' align='center'>
            {resendRemainingTime > 0 ? (
              <Typography
                style={{
                  textDecoration: 'none',
                  color: '#253786',
                  fontSize: '14px',
                  fontWeight: 600
                }}>
                {t('common:verify_email_modal.resend_code', {
                  defaultValue: 'Resend Code {{time}}',
                  time: resendRemainingTime > 0 ? resendRemainingTime : ''
                })}
              </Typography>
            ) : (
              <Link
                style={{
                  textDecoration: 'none',
                  color: '#253786',
                  fontSize: '14px',
                  fontWeight: 600
                }}
                onClick={() => resendCode()}
                href='#'>
                {t('common:verify_email_modal.resend_code', {
                  defaultValue: 'Resend Code {{time}}',
                  time: resendRemainingTime > 0 ? resendRemainingTime : ''
                })}
              </Link>
            )}
          </Typography>
          <FormButtonsWrapper>
            <CustomButton
              data-testid='cancel-verify-email'
              type='button'
              color='light'
              onClick={handleClose}>
              {t('common:verify_email_modal.buttons.cancel', 'Cancel')}
            </CustomButton>
            <LoadingButton
              disabled={String(verificationCode)?.length !== 6}
              data-testid='submit-verify-email-form'
              type='submit'
              loading={isLoadingVerify}>
              <span>{t('common:verify_email_modal.buttons.verify', 'Verify')}</span>
            </LoadingButton>
          </FormButtonsWrapper>
        </FormWrapper>
      </form>
    </ModalWrapper>
  );
};

export default VerifyEmailModal;
