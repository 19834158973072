import CustomButton from 'components/Button/CustomButton';
import { FormWrapper } from 'components/FormFields/commonStyles';
import FormComboBox, { deviceOption } from 'components/FormFields/FormComboBox';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormButtonsWrapper from '../FormInput/FormButtonsWrapper';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDevicesListInfinite, useDeviceUpdate } from 'adp-panel/hooks/api/useDevices';
import { DeviceExtendOptions, DevicesQueryParams } from '../../api/devices/device.types';
import { VALIDATOR_TEXT } from 'constants/validatorText';
import * as Sentry from '@sentry/react';
import ModalWrapper from 'components/Modals/ModalWrapper';

const deviceAssignSchema = yup.object().shape({
  device: yup.mixed().test('is-object', VALIDATOR_TEXT.REQUIRED, (value) => {
    return value !== null && typeof value === 'object';
  })
});

const AssignDevicePatientDetailsModal = ({ isModalOpen, handleModalClose, patientId }) => {
  const { t } = useTranslation();
  const defaultValues = {
    device: null
  };

  const commonQueryParams: DevicesQueryParams = {
    perpage: 1000
  };

  const queryParamsDevices: DevicesQueryParams = {
    extend: [DeviceExtendOptions.amputee],
    ...commonQueryParams
  };

  const { result: devicesData, isLoading: isLoadingDevices } =
    useDevicesListInfinite(queryParamsDevices);

  const {
    control,
    handleSubmit: handleSubmitDevice,
    reset
  } = useForm<{
    device: any;
  }>({
    defaultValues: defaultValues,
    // @ts-ignore
    resolver: yupResolver(deviceAssignSchema),
    mode: 'onChange',
    shouldUnregister: false
  });

  const { mutateAsync: updateDevice, isLoading: isLoadingUpdate } = useDeviceUpdate();

  const handleSubmit = async (e: any) => {
    const { device = undefined } = e;

    const deviceData = {
      amputee_id: patientId
    };

    try {
      await updateDevice({
        data: deviceData,
        deviceId: device.id,
        successMessage: t(
          'notifications:device_assigned_success',
          'Device has been assigned to patient'
        )
      });
      reset();
      handleModalClose();
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  return (
    <ModalWrapper
      isModalOpen={isModalOpen}
      title={t('devices:assign_device_patient_details_modal.title', 'Assign Device')}>
      <form onSubmit={handleSubmitDevice(handleSubmit)}>
        <FormWrapper>
          <FormComboBox
            label={t('devices:assign_device_patient_details_modal.form.fields.device', 'Device')}
            id={'device'}
            control={control}
            options={devicesData}
            optionLabel={'serial'}
            renderOption={(props, option) =>
              deviceOption(props, option, 'serial', option?.amputee?.name)
            }
            placeholder={t(
              'devices:assign_device_patient_details_modal.form.fields.placeholder',
              'Choose from list'
            )}
          />
          <FormButtonsWrapper>
            <CustomButton
              data-testid='cancel-medical-form'
              type='button'
              color='light'
              onClick={handleModalClose}>
              {t('devices:assign_device_patient_details_modal.form.buttons.cancel', 'Cancel')}
            </CustomButton>
            <LoadingButton
              data-testid='submit-patient-form'
              type='submit'
              loading={isLoadingUpdate}>
              <span>
                {t(
                  'devices:assign_device_patient_details_modal.form.buttons.assign',
                  'Assign device to patient'
                )}
              </span>
            </LoadingButton>
          </FormButtonsWrapper>
        </FormWrapper>
      </form>
    </ModalWrapper>
  );
};

export default AssignDevicePatientDetailsModal;
