import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { HeaderM } from 'configurator/components/atoms/Typography/Typography';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { parseBluetoothFirmware } from 'configurator/utils/funcs';
import CustomButton from 'components/Button/CustomButton';
import ModalWrapper from 'components/Modals/ModalWrapper';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';

const HandDetailsWrapper = styled.div`
  width: 350px;
`;

const Header1 = styled.h3`
  ${HeaderM};
`;

const Regular = styled.span`
  font-weight: 400;
`;

const HandDetails = ({ handleClose }: any) => {
  const { t } = useTranslation();
  const { serial, versions } = useDeviceInfoStore((state: any) => ({
    versions: state.versions,
    serial: state.serial
  }));

  return (
    <ModalWrapper title={t('configurator:component.hand_details.hand_details', 'Hand details')}>
      <HandDetailsWrapper>
        <Header1>
          {t('configurator:component.hand_details.serial_number', 'Serial number: ')}
          <Regular>{serial}</Regular>
        </Header1>
        <Header1>
          {t('configurator:component.hand_details.firmware_version', 'Firmware version: ')}
          <Regular>{parseBluetoothFirmware(versions?.current)}</Regular>
        </Header1>
      </HandDetailsWrapper>
      <FormButtonsWrapper>
        <CustomButton type='button' color='light' onClick={handleClose}>
          {t('common:common.cancel', 'Cancel')}
        </CustomButton>
      </FormButtonsWrapper>
    </ModalWrapper>
  );
};

HandDetails.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default HandDetails;
