import styled, { css } from 'styled-components';
import { ReactComponent as Check } from 'assets/circle-check-solid.svg';
import { ReactComponent as TableIcon } from 'assets/table.svg';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import { ReactComponent as EnvelopeIcon } from 'assets/envelope-solid.svg';
import { ReactComponent as NotesIcon } from 'assets/note-sticky-regular.svg';

export const IconStyle = css`
  width: 16px;
  height: 16px;
  fill: white;
`;

export const StyledCheck = styled(Check)`
  width: 14px;
  height: 14px;
  fill: green;
`;

export const StyledTableIcon = styled(TableIcon)`
  ${IconStyle};
`;

export const StyledCalendarIcon = styled(CalendarIcon)`
  ${IconStyle};
`;

export const StyledEnvelopeIcon = styled(EnvelopeIcon)`
  ${IconStyle};
`;

export const StyledNotesIcon = styled(NotesIcon)`
  ${IconStyle};
`;

export const IconBackgroundWrapper = ({ icon, primary = true }) => (
  <div
    style={{
      width: '56px',
      height: '56px',
      backgroundColor: primary ? '#dfe7fb' : '#eaedf0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px'
    }}>
    {icon}
  </div>
);
