import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormButtonsWrapper from '../FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';
import { ReactComponent as UserSvg } from 'assets/user.svg';
import { TableLink } from '../Links/Links';
import { useNavigate } from 'react-router-dom';
import ModalWrapper from 'components/Modals/ModalWrapper';

type PatientsModalProps = {
  handleClose: any;
  items: any;
  showRedirect?: boolean;
  title: string;
  link: any;
};

const MultipleItemsModal = ({
  handleClose,
  title,
  items,
  showRedirect = true,
  link
}: PatientsModalProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('patients');

  const redirectToPatient = (patientId) => {
    navigate(`${link}/${patientId}`);
  };

  return (
    <ModalWrapper title={title}>
      <ul style={{ maxHeight: '40vh', overflowY: 'auto' }}>
        {items.map((item) => (
          <li
            style={{
              listStyle: 'none'
            }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '16px 0'
              }}>
              <TableLink to={`${link}/${item?.id}`}>{item.name}</TableLink>
              {showRedirect && (
                <CustomButton
                  Icon={UserSvg}
                  color='lightBlue'
                  onClick={() => redirectToPatient(item?.id)}
                />
              )}
            </div>
            <Divider />
          </li>
        ))}
      </ul>
      <FormButtonsWrapper>
        <CustomButton color='light' onClick={handleClose}>
          {t('patients:modal.buttons.cancel', 'Cancel')}
        </CustomButton>
      </FormButtonsWrapper>
    </ModalWrapper>
  );
};

export default MultipleItemsModal;
