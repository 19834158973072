import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import type { SxProps } from '@mui/material';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', sm: '70%', md: '50%', lg: '40%', xl: '30%' },
  bgcolor: 'background.paper',
  boxShadow: 24,
  maxHeight: '90vh',
  overflow: 'hidden',
  p: 3,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column'
} as const;

const contentStyle = {
  overflowY: 'auto',
  flex: 1
};

interface ModalWrapperProps {
  title?: string;
  children: React.ReactNode;
  isModalOpen?: boolean;
  sx?: SxProps;
  maxWidth?: string;
}

const ModalWrapper = ({
  title,
  children,
  isModalOpen = true,
  sx,
  maxWidth = 'unset'
}: ModalWrapperProps) => {
  return (
    <Modal open={isModalOpen} aria-labelledby='modal'>
      <Box
        sx={{
          ...modalStyle,
          ...sx,
          maxWidth: maxWidth
        }}>
        {title && (
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            mb={2}
            aria-labelledby='modal'>
            <Typography variant='h5'>{title}</Typography>
          </Box>
        )}
        <Box sx={contentStyle}>{children}</Box>
      </Box>
    </Modal>
  );
};

export default ModalWrapper;
