import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Checkbox, CircularProgress, FormControlLabel, Modal } from '@mui/material';
import FormButtonsWrapper from '../FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';
import { permissions } from 'adp-panel/constants/permissions';
import { produce } from 'immer';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { UserExtendOptions, UsersQueryParams } from 'adp-panel/api/users/users.types';
import { useUser, useUserUpdate } from 'adp-panel/hooks/api/useUsers';
import {
  comparePermissions,
  convertToPermissionString,
  extractPermissionsFromApi
} from 'adp-panel/hoc/useCanAccess';
import { permanentPermissions } from 'adp-panel/constants/permanentPermissions';
import { PermissionEntry } from 'adp-panel/api/users/users.types';
import { useTranslation } from 'react-i18next';
import { UserUpdatePayload } from 'adp-panel/api/users/users.types';
import { NotificationFactory } from 'lib/NotificationFactory';
import LoaderWrapper from 'components/Loader/Loader';
import ModalWrapper from 'components/Modals/ModalWrapper';

type PermissionModalProps = {
  handleClose: any;
  clinicianId: number;
};

type PermissionsType = {
  text: string;
  id: string;
  selected: boolean;
  items: { text: string; id: string; selected: boolean }[] | null;
};

const parsePermissions = (selectedPermissions: PermissionsType[]) => {
  const _selectedPermissions: any[] = [];
  for (let i = 0; i < selectedPermissions.length; i++) {
    const element = selectedPermissions[i];
    if (element.selected) _selectedPermissions.push({ id: element.id });

    if (element.items) {
      for (let j = 0; j < element.items.length; j++) {
        const _element = element.items[j];
        if (_element.selected) _selectedPermissions.push({ id: _element.id });
      }
    }
  }
  return _selectedPermissions;
};

const transformPermissions = (clinicianPermissions, permissions) => {
  const extracted = extractPermissionsFromApi(clinicianPermissions ?? [], permissions);
  return permissions.map((permission) => {
    const isSelectedInApi = extracted.find(
      (extractedPermission) => extractedPermission.id === permission.id
    );
    return {
      ...permission,
      selected: Boolean(isSelectedInApi),
      items: permission?.items
        ? permission.items.map((item) => {
            const isSelectedInApiItem = extracted.find(
              (extractedPermission) => extractedPermission.id === item.id
            );
            return {
              ...item,
              selected: Boolean(isSelectedInApiItem)
            };
          })
        : null
    };
  });
};

const PermissionsModal = ({ handleClose, clinicianId }: PermissionModalProps) => {
  const { t } = useTranslation('clinicians');
  const queryParams: UsersQueryParams = {
    extend: [UserExtendOptions.permissions, UserExtendOptions.patients]
  };
  const { result: clinician, isLoading } = useUser(Number(clinicianId), queryParams);
  const extracted = extractPermissionsFromApi(clinician?.permissions ?? [], permissions);
  const [selectedPermissions, setSelectedPermissions] = useState<PermissionsType[]>();
  const [showExpanded, setShowExpanded] = useState(
    permissions
      .filter((permission) => permission.items)
      .map((permission) => ({
        id: permission.id,
        show: true
      }))
  );
  const { mutateAsync: editUser, isLoading: isLoadingUpdated } = useUserUpdate();

  const handleSavePermissions = async () => {
    if (!selectedPermissions) return;

    const parsed = parsePermissions(selectedPermissions);

    if (!clinician?.patients || !clinician?.permissions) return;

    const permissionFromForm = convertToPermissionString([...parsed, ...permanentPermissions]);
    const permissionFromApi = clinician.permissions.map((item: PermissionEntry) => item.name);
    const toAdd = comparePermissions(permissionFromForm, permissionFromApi);
    const toRemove = comparePermissions(permissionFromApi, permissionFromForm);

    const permissionsKey = {
      ...(toAdd.length > 0 && { add: toAdd }),
      ...(toRemove.length > 0 && { del: toRemove })
    };

    try {
      await editUser({
        id: clinician.id,
        permissions: permissionsKey
      } as UserUpdatePayload);
      NotificationFactory.successNotification(
        t('notifications:component.edit_clinician.notification.success', 'User updated')
      );
      handleClose();
    } catch (error) {
      Sentry.captureException(error);
      NotificationFactory.errorNotification(
        t('notifications:component.edit_clinician.notification.error', 'Failed to update user')
      );
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setSelectedPermissions(transformPermissions(clinician?.permissions, permissions));
    }
  }, [isLoading, JSON.stringify(extracted)]);

  return (
    <ModalWrapper title='Permissions to ADP and Zeus Configurator'>
      {isLoading ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <form style={{ display: 'flex', flexDirection: 'column' }}>
          {selectedPermissions &&
            selectedPermissions.map((permission) => {
              const isExpanded = showExpanded.find((expanded) => expanded.id === permission.id)
                ?.show;
              return permission?.items ? (
                <div key={permission.text}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <KeyboardArrowDownIcon
                      sx={{
                        marginRight: '6px',
                        cursor: 'pointer',
                        transform: `rotate(${isExpanded ? '0' : '-90'}deg)`
                      }}
                      onClick={() =>
                        setShowExpanded(
                          produce((prev) => {
                            const expandedPermission = prev.find(
                              (_expanded) => _expanded.id === permission.id
                            );
                            if (!expandedPermission) return;
                            expandedPermission.show = !expandedPermission.show;
                          })
                        )
                      }
                    />
                    <FormControlLabel
                      label={permission.text}
                      control={
                        <Checkbox
                          checked={permission?.items.every(
                            (_permission) => _permission.selected === true
                          )}
                          indeterminate={
                            Boolean(
                              permission?.items.find((_permission) => _permission.selected === true)
                            ) &&
                            Boolean(
                              permission?.items.find(
                                (_permission) => _permission.selected === false
                              )
                            )
                          }
                          onChange={(e) =>
                            setSelectedPermissions(
                              produce((prev) => {
                                const _permission = prev?.find(
                                  (_permission) => _permission.id === permission.id
                                );
                                _permission!.items = _permission!.items!.map((__permission) => {
                                  const isSelected = _permission?.items?.find(
                                    (__permission) => __permission.selected === true
                                  );
                                  return {
                                    ...__permission,
                                    selected: isSelected ? false : true
                                  };
                                });
                              })
                            )
                          }
                        />
                      }
                    />
                  </div>
                  {isExpanded && (
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '50px' }}>
                      {permission.items.map((_permission) => (
                        <FormControlLabel
                          key={_permission.id}
                          label={_permission.text}
                          control={
                            <Checkbox
                              checked={_permission.selected}
                              onChange={(e) =>
                                setSelectedPermissions(
                                  produce((prev) => {
                                    const oldPermission = prev?.find(
                                      (__permission) => __permission.id === permission.id
                                    );
                                    if (!oldPermission) return;
                                    const oldPermissionItem = oldPermission.items?.find(
                                      (__permission) => __permission.id === _permission.id
                                    );
                                    if (!oldPermissionItem) return;
                                    oldPermissionItem.selected = !oldPermissionItem.selected;
                                  })
                                )
                              }
                            />
                          }
                        />
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <FormControlLabel
                  key={permission.id}
                  sx={{ marginLeft: '19px' }}
                  label={permission.text}
                  control={
                    <Checkbox
                      checked={permission.selected}
                      onChange={(e) =>
                        setSelectedPermissions(
                          produce((prev) => {
                            const oldPermission = prev?.find(
                              (__permission) => __permission.id === permission.id
                            );
                            if (!oldPermission) return;
                            oldPermission.selected = !oldPermission.selected;
                          })
                        )
                      }
                    />
                  }
                />
              );
            })}
          <FormButtonsWrapper>
            <CustomButton color='light' onClick={handleClose}>
              Cancel
            </CustomButton>
            <CustomButton onClick={handleSavePermissions} loading={isLoadingUpdated}>
              Save permissions
            </CustomButton>
          </FormButtonsWrapper>
        </form>
      )}
    </ModalWrapper>
  );
};

export default PermissionsModal;
