import { MenuItem } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import { FormWrapper } from 'components/FormFields/commonStyles';
import { useTranslation } from 'react-i18next';
import { useConfigStore } from 'configurator/reducers/configStore';
import useModes from 'configurator/hooks/useModes';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomDropdown from 'components/FormFields/CustomDropdown';
import { useState } from 'react';
import ModalWrapper from 'components/Modals/ModalWrapper';

const ChangeModeModal = ({ handleModalClose }) => {
  const { t } = useTranslation();
  const {
    slotSelected,
    config: { modes }
  } = useConfigStore((state) => state);

  const [selectedMode, setSelectedMode] = useState(slotSelected);
  const { handleChangeMode } = useModes();

  const handleConfirm = () => {
    handleChangeMode(selectedMode);
    handleModalClose();
  };

  return (
    <ModalWrapper title={t('configurator:component.change_mode_modal.title', 'Change mode')}>
      <FormWrapper>
        <CustomDropdown
          id='selected-mode'
          label={t('configurator:component.change_mode_modal.selected_mode', 'Selected mode')}
          value={selectedMode}
          onChange={setSelectedMode}
          disabled={Boolean(slotSelected === null || modes?.length === 0)}
          options={modes.map((mode) => (
            <MenuItem
              key={`selected-mode-select_${mode.name}`}
              value={mode.slot}
              disabled={mode.active === 0}>
              {mode.name}
            </MenuItem>
          ))}
        />
        <FormButtonsWrapper>
          <CustomButton
            data-testid='cancel-change-mode-modal'
            type='button'
            color='light'
            onClick={handleModalClose}>
            {t('configurator:component.change_mode_modal.cancel', 'Cancel')}
          </CustomButton>
          <CustomButton
            data-testid='cancel-change-mode-modal'
            type='button'
            onClick={handleConfirm}>
            {t('configurator:component.change_mode_modal.confirm', 'Confirm')}
          </CustomButton>
        </FormButtonsWrapper>
      </FormWrapper>
    </ModalWrapper>
  );
};

export default ChangeModeModal;
