/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import { LoadingButton } from '@mui/lab';
import ModalWrapper from 'components/Modals/ModalWrapper';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';

const ParagraphBold = styled.p`
  ${TextS};
  margin-bottom: 20px;
`;

type SessionReconnectionModalProps = {
  stopSessionAction: () => void;
  remainingTimeInSeconds: number;
  isLoading: boolean;
  isUnsaved: boolean;
};

let remainingTimeInterval: ReturnType<typeof setInterval> | null = null;

const SessionReconnectionModal = ({
  stopSessionAction,
  isLoading = false,
  remainingTimeInSeconds = 600,
  isUnsaved
}: SessionReconnectionModalProps) => {
  const { t } = useTranslation();

  const [autoSessionCloseRemainingTime, setAutoSessionCloseRemainingTime] =
    useState(remainingTimeInSeconds);
  useEffect(() => {
    if (autoSessionCloseRemainingTime <= 0) {
      if (remainingTimeInterval) clearInterval(remainingTimeInterval);
      setAutoSessionCloseRemainingTime(0);
      stopSessionAction();
    }
  }, [autoSessionCloseRemainingTime]);

  useEffect(() => {
    remainingTimeInterval = setInterval(() => {
      setAutoSessionCloseRemainingTime((prev) => prev - 1);
    }, 1000);

    return () => {
      if (remainingTimeInterval) clearInterval(remainingTimeInterval);
    };
  }, []);

  const minutes = Math.floor(autoSessionCloseRemainingTime / 60);
  const seconds = autoSessionCloseRemainingTime - minutes * 60;

  return (
    <ModalWrapper
      title={t(
        'configurator:component.session_reconnection_modal.session_reconnection',
        'Session reconnection'
      )}>
      <ParagraphBold>
        {t(
          'configurator:component.session_reconnection_modal.patient_disconnected',
          'Patient has disconnected from session, they have {{minutes}} minutes {{seconds}} seconds to reconnect, after that time session will stop automatically.',
          { minutes, seconds }
        )}
        {isUnsaved &&
          t(
            'configurator:component.session_reconnection_modal.you_can_still_save_changes',
            'You will still be able to save current changes.'
          )}
      </ParagraphBold>
      <FormButtonsWrapper>
        <LoadingButton color='error' onClick={stopSessionAction} loading={isLoading}>
          <span>
            {t(
              'configurator:component.session_reconnection_modal.stop_session_now',
              'Stop session now'
            )}
          </span>
        </LoadingButton>
      </FormButtonsWrapper>
    </ModalWrapper>
  );
};

export default SessionReconnectionModal;
