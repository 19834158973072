import { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import { MenuItem, TextField } from '@mui/material';
import ErrorLabel from 'configurator/components/atoms/Error/ErrorLabel';
import { getPCBVersionAPI } from 'configurator/api/versions/versions';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';

const Wrapper = styled.form`
  display: grid;
  gap: 24px;
`;

type PcbReplacementBluetoothProps = {
  handleNext: Function;
  handleBluetooth: Function;
  handlePcb: Function;
  bluetoothId: string | undefined;
  pcb: any | undefined;
  handleClose: any;
};

export const PCB_VERSION_QUERY_KEY = 'version/pcb';

const PcbReplacementBluetooth = ({
  handleNext,
  handleBluetooth,
  bluetoothId,
  handlePcb,
  pcb,
  handleClose
}: PcbReplacementBluetoothProps) => {
  const { data: pcbVersions } = useQuery([PCB_VERSION_QUERY_KEY], () => getPCBVersionAPI());
  const [showError, setShowError] = useState(false);

  const onSubmit = () => {
    if (!bluetoothId || !pcb) {
      setShowError(true);
      return;
    }
    handleNext();
  };

  return (
    <Wrapper>
      <p>Type Bluetooth ID of the new PCB in order to proceed.</p>
      <TextField
        id='bluetoothId'
        label='Bluetooth ID'
        required
        onChange={(e) => handleBluetooth(e.target.value)}
        value={bluetoothId || ''}
      />
      {pcbVersions && (
        <TextField
          id='selected-pcb'
          select
          label='PCB version'
          required
          SelectProps={{
            onChange: (e) =>
              handlePcb(pcbVersions.find((version) => version?.id === e.target.value)),
            value: pcb?.id || ''
          }}>
          {pcbVersions.map((pcbVersion) => (
            <MenuItem key={`selected-pcb_${pcbVersion?.id}`} value={pcbVersion?.id}>
              {pcbVersion?.name}
            </MenuItem>
          ))}
        </TextField>
      )}
      {showError && <ErrorLabel>This fields are required</ErrorLabel>}
      <FormButtonsWrapper>
        <CustomButton color='light' onClick={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton onClick={onSubmit}>Next step</CustomButton>
      </FormButtonsWrapper>
    </Wrapper>
  );
};

export default PcbReplacementBluetooth;
